import { useTranslation } from 'react-i18next';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type RecordRule,
  type RecordRuleConnectionKey,
  type RecordRuleValue
} from '@/types/schema/rules/RecordRule';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { capitalize } from '@/utils/formatters';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackObject } from '@/components/InlineKnackObject';
import { InlineKnackValue } from '@/components/InlineKnackValue';

export function ViewRecordRuleCardConnectionContent({ recordRule }: { recordRule: RecordRule }) {
  const [t] = useTranslation();
  const { getFieldByKey } = useFieldHelpers();

  if (!recordRule.connection) return null;

  const getObjectKeyFromRecordRuleConnection = (connectionKey: RecordRuleConnectionKey) =>
    connectionKey.split('.')[0] as KnackObject['key'];

  const getFieldKeyFromRecordRuleValueConnection = (
    connectionKey: RecordRuleValue['connection_field']
  ) => {
    if (!connectionKey) {
      return null;
    }
    return connectionKey.split('-')[1] as KnackFieldKey;
  };

  return (
    <div className="flex flex-wrap items-center gap-1">
      <span>{capitalize(t('components.rules.for'))}</span>
      <InlineKnackObject objectKey={getObjectKeyFromRecordRuleConnection(recordRule.connection)} />
      <p>{t('components.record_rule_card.connected_to_this_record')}</p>
      <p>{t('components.record_rule_card.set_the_value_of')}</p>
      {recordRule.values.map((value, valueIndex) => {
        const connectedField = getFieldByKey(value.field);
        const inputField = value.input ? getFieldByKey(value.input as KnackFieldKey) : null;
        const connectionTargetFieldKey = getFieldKeyFromRecordRuleValueConnection(
          value.connection_field
        );
        const connectedTargetField = connectionTargetFieldKey
          ? getFieldByKey(connectionTargetFieldKey)
          : null;

        if (!connectedField) {
          return null;
        }

        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${value.field}-${value.value}-${valueIndex}`}
            className="flex flex-wrap items-center gap-1"
          >
            {recordRule.values.length > 1 && valueIndex > 0 && (
              <span>{t('components.rules.and')}</span>
            )}
            <InlineKnackField
              fieldName={connectedField.name}
              fieldType={connectedField.type}
              className="border-none bg-action"
            />
            <span>{t('keywords.to')}</span>
            {value.type === 'record' && inputField && (
              <InlineKnackField
                fieldName={inputField.name}
                fieldType={inputField.type}
                className="border-none bg-action"
              />
            )}
            {value.connection_field && value.type === 'connection' && connectedTargetField && (
              <InlineKnackField
                fieldName={connectedTargetField.name}
                fieldType={connectedTargetField.type}
                className="border-none bg-action"
              />
            )}
            {value.value && value.type === 'value' && (
              <InlineKnackValue value={value.value} className="border-none bg-action" />
            )}
          </div>
        );
      })}
    </div>
  );
}
