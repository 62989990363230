import { Controller, FormProvider, useForm, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Divider, Label, Select } from '@knack/asterisk-react';
import { type IssueData } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import {
  RECORD_RULE_ACTION_TYPES,
  type RecordRule,
  type RecordRuleActionType,
  type RecordRuleConnectionKey
} from '@/types/schema/rules/RecordRule';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useRecordRuleHelpers } from '@/hooks/useRecordRuleHelpers';
import { cn } from '@/utils/tailwind';
import { SendCustomEmailForm } from '@/components/email/SendCustomEmailForm';
import { RecordRuleConnectionSelect } from '@/components/record-rule/RecordRuleConnectionSelect';
import { ViewRecordRuleFormCriteria } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleFormCriteria';
import { ViewRecordRuleValueGroup } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleValueGroup';

interface ViewRecordRuleFormDialogProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
  formIntent?: 'edit' | 'add';
  onOpenChange: (isOpen: boolean) => void;
  onRuleSave: (updatedSubmitRule: RecordRule) => void;
}

export function ViewRecordRuleFormDialog({
  recordRule,
  sourceObject,
  formIntent = 'edit',
  onOpenChange,
  onRuleSave
}: ViewRecordRuleFormDialogProps) {
  const [t] = useTranslation();
  const { convertFieldNamesToFieldKeys } = useFieldHelpers();
  const { getFormattedConnectedObjects } = useObjectHelpers();
  const { validateCriteriaValues } = useCriteriaHelpers();
  const { recordRuleSchema, getDefaultRecordValue } = useRecordRuleHelpers();

  const allConnectedObjects = getFormattedConnectedObjects(sourceObject);

  const recordRuleFormSchema = recordRuleSchema.superRefine((data, context) => {
    const addIssueToErrorContext = (
      path: IssueData['path'],
      message: string | undefined = 'errors.value_required'
    ) => {
      context.addIssue({
        path,
        message: t(message),
        code: 'custom'
      });
    };

    // Transform email subjects and email message from {field name} to {field_key} e.g {First Name} to {field_1}
    if (data.action === 'email') {
      if (data.email?.subject) {
        data.email.subject = convertFieldNamesToFieldKeys(data.email.subject, sourceObject.fields);
      }

      if (data.email?.message) {
        data.email.message = convertFieldNamesToFieldKeys(data.email.message, sourceObject.fields);
      }
    }

    // Validate criteria values
    const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

    if (criteriaValueErrors.length) {
      criteriaValueErrors.forEach((error) => {
        addIssueToErrorContext(error.path, error.message);
      });
    }

    // Validate email recipients
    if (data.action === 'email' && data.email?.recipients) {
      data.email.recipients.forEach((recipient, recipientIndex) => {
        if (recipient.recipient_type === 'field' && !recipient.field) {
          addIssueToErrorContext([`action.email.recipients.${recipientIndex}.field`]);
        }
        if (recipient.recipient_type === 'custom' && !recipient.email) {
          addIssueToErrorContext([`action.email.recipients.${recipientIndex}.email`]);
        }
      });
    }

    // Validate record rule values
    if (data.action !== 'email' && data.values.length !== 0) {
      data.values.forEach((value, valueIndex) => {
        if (value.type === 'record' && !value.input) {
          addIssueToErrorContext([`values.${valueIndex}.input`]);
        }
        if (!value.value) {
          addIssueToErrorContext([`values.${valueIndex}.value`]);
        }
        if (value.type === 'connection' && !value.connection_field) {
          addIssueToErrorContext([`values.${valueIndex}.connection_field`]);
        }
      });
    }
  });

  const form = useForm<RecordRule>({
    resolver: zodResolver(recordRuleFormSchema),
    defaultValues: {
      ...recordRule,
      values:
        recordRule.values.length === 0
          ? [getDefaultRecordValue(sourceObject.fields[0].key)]
          : recordRule.values
    }
  });

  const {
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    watch
  } = form;

  const [recordRuleActionType, connectionKey] = watch(['action', 'connection']);
  const tableConnections = [
    ...sourceObject.connections.inbound,
    ...sourceObject.connections.outbound
  ];

  const onSubmit = (updatedRecordRule: RecordRule) => {
    onRuleSave(updatedRecordRule);
  };

  const getConnectedObjectFromConnectionKey = (key: RecordRuleConnectionKey) => {
    if (!key) {
      return sourceObject;
    }
    const [sourceObjectKey] = key.split('.');

    const connectedObject = allConnectedObjects.find((o) => o.object.key === sourceObjectKey);

    if (!connectedObject) {
      return sourceObject;
    }

    return connectedObject.object;
  };

  const handleChangeRecordRuleActionType = (
    actionType: RecordRuleActionType,
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange(actionType);
    if (
      (actionType === 'connection' || actionType === 'insert') &&
      allConnectedObjects.length > 0
    ) {
      setValue('values', [getDefaultRecordValue(allConnectedObjects[0].object.fields[0].key)]);
    }
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <Dialog.MainContent>
              <Dialog.Header>
                <Dialog.Title>
                  {formIntent === 'edit'
                    ? t('components.record_rule_card.edit_modal.title')
                    : t('components.record_rule_card.add_modal.title')}
                </Dialog.Title>
                <Dialog.Description className="sr-only">
                  {formIntent === 'edit'
                    ? t('components.record_rule_card.edit_modal.description')
                    : t('components.record_rule_card.add_modal.description')}
                </Dialog.Description>
              </Dialog.Header>

              <div className="mt-6">
                <div className="mb-4">
                  <p className="mb-2 font-medium">
                    {t('components.record_rule_card.edit_modal.action_type')}
                  </p>
                  <Controller
                    name="action"
                    render={({ field: { value: selectedActionType, onChange } }) => (
                      <Select
                        value={selectedActionType}
                        onValueChange={(val: RecordRuleActionType) => {
                          handleChangeRecordRuleActionType(val, onChange);
                          clearErrors();
                        }}
                      >
                        <Select.Trigger
                          placeholder={t('actions.select')}
                          className={cn('w-full', {
                            'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                              errors?.action
                          })}
                        />
                        <Select.Content>
                          {RECORD_RULE_ACTION_TYPES.map((actionType) => (
                            <Select.Item key={actionType} value={actionType}>
                              {t(`components.record_rule_card.actions.${actionType}`)}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>
                {(recordRuleActionType === 'connection' || recordRuleActionType === 'insert') && (
                  <div className="mb-4">
                    <RecordRuleConnectionSelect
                      tableConnections={tableConnections}
                      isInsert={recordRuleActionType === 'insert'}
                    />
                  </div>
                )}
                {recordRuleActionType !== 'email' && (
                  <div>
                    <Label className="block text-emphasis">
                      {t('components.record_rule_card.update_field_value')}
                    </Label>
                    <div className="mt-2 space-y-2 rounded-lg bg-subtle p-3">
                      <ViewRecordRuleValueGroup
                        availableFields={
                          (connectionKey && recordRuleActionType === 'connection') ||
                          (recordRuleActionType === 'insert' && connectionKey)
                            ? getConnectedObjectFromConnectionKey(connectionKey).fields
                            : sourceObject.fields
                        }
                      />
                    </div>
                  </div>
                )}
                {recordRuleActionType === 'email' && (
                  <SendCustomEmailForm
                    sourceTableObject={sourceObject}
                    formFieldNamePrefix="email"
                  />
                )}
                <Divider className="my-6" />
                <h3 className="mb-1 font-medium">{t('components.rules.conditions')}</h3>
                <p className="mb-4 text-xs text-subtle">
                  {t('components.rules.apply_action_when_conditions_are_met')}
                </p>
                <ViewRecordRuleFormCriteria sourceObject={sourceObject} />
              </div>
            </Dialog.MainContent>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal">{t('actions.cancel')}</Button>
              </Dialog.Close>
              <Button type="submit" data-testid="view-record-rule-modal-submit">
                {formIntent === 'edit'
                  ? t('components.record_rule_card.edit_modal.submit_button')
                  : t('components.record_rule_card.add_modal.submit_button')}
              </Button>
            </Dialog.Footer>
          </form>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}
