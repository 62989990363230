import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiArrowRight as ArrowIcon,
  HiChevronDown as ChevronDownIcon,
  HiArrowTopRightOnSquare as LinkIcon
} from 'react-icons/hi2';
import { Collapsible } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface HelpMenuItemProps {
  icon: string;
  title: string;
  description: string;
  link?: string;
  collapsibleItems?: {
    icon: string;
    title: string;
    description: string;
    link: string;
  }[];
  enabled?: boolean;
  onClick?: () => void;
}

interface WrapperProps {
  children: React.ReactNode;
  classes: string;
  link?: string;
  onClick?: () => void;
}

function Wrapper({ children, classes, link, onClick }: WrapperProps) {
  return link ? (
    <a
      href={link}
      className={classes}
      target="_blank"
      rel="noreferrer"
      data-testid="help-menu-item-link"
    >
      {children}
    </a>
  ) : (
    <button type="button" className={classes} onClick={onClick} data-testid="help-menu-item-button">
      {children}
    </button>
  );
}

function MenuItemBody({ icon, title, description }) {
  return (
    <div className="flex items-center gap-3">
      <img src={icon} className="size-10" alt={`${title} icon`} data-testid="help-menu-item-icon" />
      <div className="space-y-1">
        <p className="text-left text-emphasis">{title}</p>
        <p className="text-left text-xs text-default">{description}</p>
      </div>
    </div>
  );
}

export function MenuItem({
  icon,
  title,
  description,
  link,
  collapsibleItems,
  enabled = true,
  onClick
}: HelpMenuItemProps) {
  const [t] = useTranslation();
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
  const wrapperClasses =
    'group flex items-center justify-between rounded-lg p-2 hover:bg-brand-50 w-full hover:-translate-y-0.5 transition-all gap-2';

  if (!enabled) {
    return null;
  }

  return collapsibleItems ? (
    <Collapsible onOpenChange={setIsCollapsibleOpen}>
      <Collapsible.Trigger
        className={wrapperClasses}
        data-testid="help-menu-item-collapsible-trigger"
      >
        <MenuItemBody title={title} icon={icon} description={description} />
        <div>
          <ChevronDownIcon
            size={32}
            className={cn('transition-transform duration-300', {
              'rotate-180': isCollapsibleOpen
            })}
          />
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content
        className="collapsible-animation mt-2 rounded-lg bg-muted"
        data-testid="help-menu-collapsible-content"
      >
        {collapsibleItems?.map((item) => (
          <a
            aria-label={t('components.help_menu.item_link_label')}
            key={item.title}
            className={wrapperClasses}
            href={item.link}
            target="_blank"
            rel="noreferrer"
            data-testid="help-menu-item-collapsible-link"
          >
            <MenuItemBody title={item.title} icon={item.icon} description={item.description} />
            <div>
              <LinkIcon size={32} />
            </div>
          </a>
        ))}
      </Collapsible.Content>
    </Collapsible>
  ) : (
    <Wrapper classes={wrapperClasses} onClick={onClick} link={link}>
      <MenuItemBody title={title} icon={icon} description={description} />
      <div>{link ? <LinkIcon size={32} /> : <ArrowIcon size={32} />}</div>
    </Wrapper>
  );
}
