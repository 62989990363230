import { Children, Fragment, type ReactNode } from 'react';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Collapsible, Divider } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface BuilderAccordionItemProps extends React.ComponentPropsWithoutRef<typeof Collapsible> {
  isDefaultOpen: boolean;
  label?: string;
  customLabel?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  removeItemButton?: ReactNode;
  children: ReactNode;
}

function BuilderAccordionItem({
  isDefaultOpen,
  label,
  customLabel,
  className,
  contentClassName,
  removeItemButton,
  children,
  ...props
}: BuilderAccordionItemProps) {
  return (
    <Collapsible defaultOpen={isDefaultOpen} className={cn('w-full', className)} {...props}>
      <Collapsible.Trigger className="group flex w-full items-center transition-all">
        {customLabel && customLabel}
        {!customLabel && label && <span className="font-medium text-emphasis">{label}</span>}
        <ChevronDownIcon
          size={14}
          className="ml-auto transition-transform duration-300 group-data-[state=open]:rotate-180"
        />
        {removeItemButton}
      </Collapsible.Trigger>
      <Collapsible.Content className={cn('mt-4', contentClassName)}>{children}</Collapsible.Content>
    </Collapsible>
  );
}

function BuilderAccordion({ children }: { children: React.ReactNode }) {
  // Convert the children into an array and filter out "falsy" values, we might want to conditionally render items
  const filteredChildren = Children.toArray(children).filter(Boolean);
  const numberOfAccordionItems = filteredChildren.length;

  // If the accordion has more than one item, add a divider between each item
  if (numberOfAccordionItems > 1) {
    return filteredChildren.map((child, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`accordion-${index}`}>
        {child}

        {/* Only add the divider if it's not the last item */}
        {index + 1 !== numberOfAccordionItems && <Divider className="my-4" />}
      </Fragment>
    ));
  }

  // Otherwise, return the children as is
  return children;
}

const CompoundBuilderAccordion = Object.assign(BuilderAccordion, {
  Item: BuilderAccordionItem
});

export { CompoundBuilderAccordion as BuilderAccordion };
