import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiDatabase as DatabaseIcon, HiPlus as PlusIcon } from 'react-icons/hi';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { DataTable } from '@/components/data-table/display/DataTable';
import { useSelectionEvents } from '@/components/data-table/display/useSelectionEvents';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';
import { EmptyState } from '@/components/EmptyState';
import { useGetLatestJob } from '@/components/jobs/useGetLatestJob';
import { useTablesPageContext } from '@/pages/tables/TablesPageContext';

export function DataTableDisplay() {
  const [t] = useTranslation();
  const { refetch: refetchApp } = useApplicationQuery();
  const { newRow } = useDataTableStore().use.actions();
  const isFetchingPages = useDataTableStore().use.isFetchingPages();
  const isDraftModeEnabled = useDataTableStore().use.isDraftModeEnabled();
  const dataOrder = useDataTableStore().use.dataOrder();
  const filters = useDataTableStorePersist().use.filters();
  const { refetchPagesInViewport } = useDataTableStore().use.actions();
  const table = useCurrentTable();

  const { setHasPendingChanges } = useGlobalState((state) => ({
    setHasPendingChanges: state.actions.setIsDraftModeEnabled
  }));

  const { setIsToolkitSidebarOpen, setActiveToolkitSidebarItem, isToolkitSidebarOpen } =
    useTablesPageContext();

  const handleNewRowButton = () => {
    newRow();
    if (isToolkitSidebarOpen) {
      setActiveToolkitSidebarItem('');
      setIsToolkitSidebarOpen(false);
    }
  };

  useEffect(() => {
    setHasPendingChanges(isDraftModeEnabled);
  }, [isDraftModeEnabled, setHasPendingChanges]);

  useSelectionEvents();

  const { latestJob, previousJobState } = useGetLatestJob();
  // Refresh the pages when the job finished
  useEffect(() => {
    const refetch = async () => {
      await refetchApp();
      refetchPagesInViewport();
    };

    if (
      previousJobState?.jobStatus &&
      previousJobState?.jobStatus !== latestJob?.jobStatus &&
      latestJob?.jobStatus === 'completed'
    ) {
      void refetch();
    }
  }, [latestJob?.jobStatus, previousJobState?.jobStatus, refetchApp, refetchPagesInViewport]);

  // Don't show the empty state if there are filters applied
  if (dataOrder.length === 0 && !filters && !isFetchingPages) {
    const translationPrefix = table?.type === 'UserObject' ? 'users' : 'records';

    return (
      <div
        className="flex grow items-center justify-center"
        data-testid="data-table-display-container"
      >
        <EmptyState>
          <EmptyState.Icon icon={<DatabaseIcon />} />
          <EmptyState.Title>
            {t(`components.empty_states.${translationPrefix}.title`)}
          </EmptyState.Title>
          <EmptyState.Description>
            {t(`components.empty_states.${translationPrefix}.description`)}
          </EmptyState.Description>
          <EmptyState.Cta
            label={t(`components.empty_states.${translationPrefix}.cta`)}
            icon={<PlusIcon />}
            callback={handleNewRowButton}
          />
          <EmptyState.Link
            link={
              table?.type === 'UserObject'
                ? 'https://learn.knack.com/article/users-logins'
                : 'https://learn.knack.com/article/vdhhuysjch-working-with-records'
            }
            linkText={t(`components.empty_states.${translationPrefix}.link_text`)}
            isExternalLink
          />
        </EmptyState>
      </div>
    );
  }

  return <DataTable />;
}
