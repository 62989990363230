import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type DateTimeFieldValidationRuleCriteria } from '@/types/schema/fields';
import {
  type KnackField,
  type ValidationRule,
  type ValidationRuleCriteria,
  type ValidationRuleCriteriaForAsset
} from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { getBooleanFieldLabel } from '@/utils/fields';
import { getDateTimeValidationRuleCriteriaValue } from '@/utils/rules/getDateTimeValidationRuleCriteriaValue';
import { cn } from '@/utils/tailwind';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface ValidationRuleCriteriaValueProps {
  criteria: ValidationRuleCriteria;
  criteriaField: KnackField;
  dataTestId?: string;
}

function ValidationRuleCriteriaValue({
  criteria,
  criteriaField,
  dataTestId
}: ValidationRuleCriteriaValueProps) {
  const { getRoleObjects } = useObjectHelpers();

  if (shouldHideValueBasedOnOperator(criteria.operator)) return null;

  // If the criteria value is an array, it means it's a connection field, so we need to display the record value
  if (Array.isArray(criteria.value) && criteriaField.type === 'connection') {
    return criteria.value.map((value, valueIndex) => (
      <InlineKnackRecordValue
        // eslint-disable-next-line react/no-array-index-key
        key={`${value}-${valueIndex}`}
        tableKey={criteriaField.relationship.object}
        recordId={value}
        dataTestId={dataTestId}
      />
    ));
  }

  let valueToReturn: string | undefined;

  if (criteriaField.type === 'image' || criteriaField.type === 'file') {
    const { unit, value } = criteria as ValidationRuleCriteriaForAsset;

    if (criteria.operator === 'file type is' || criteria.operator === 'file type is not') {
      valueToReturn = `${value}`;
    } else {
      valueToReturn = `${value} ${unit}`;
    }
  } else if (criteriaField.type === 'date_time') {
    valueToReturn = getDateTimeValidationRuleCriteriaValue(
      criteria as DateTimeFieldValidationRuleCriteria
    );
  } else if (criteriaField.type === 'user_roles') {
    const table = getRoleObjects().find((role) => role.profile_key === criteria.value);
    if (!table) return null;
    valueToReturn = table.name;
  } else if (criteriaField.type === 'boolean' && typeof criteria.value === 'boolean') {
    valueToReturn = getBooleanFieldLabel(criteriaField.format, criteria.value);
  } else if (typeof criteria.value === 'string') {
    valueToReturn = criteria.value;
  }

  if (!valueToReturn) {
    return null;
  }

  return (
    <div className="mt-2">
      {' '}
      <InlineKnackValue
        value={valueToReturn}
        data-testid={dataTestId}
        className="inline-flex max-w-sm items-center truncate rounded-md bg-subtle p-1 align-middle leading-none text-emphasis group-hover:bg-muted"
      />
    </div>
  );
}

export function ValidationRuleCard({
  rule,
  table,
  className,
  children
}: {
  rule: ValidationRule;
  table: KnackObject;
  className?: string;
  children?: React.ReactNode;
}) {
  const [t] = useTranslation();

  return (
    <Card
      className={cn('bg-muted p-4 shadow-none sm:p-4', className)}
      data-testid="validation-rule-card"
    >
      <span>{t('components.rules.when')}</span>{' '}
      {rule.criteria?.map((criteria: ValidationRuleCriteria, index: number) => {
        const criteriaField = table.fields.find((f) => f.key === criteria.field);
        if (!criteriaField) return null;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${criteria.field}-${index}`}>
            <InlineKnackField
              fieldType={criteriaField.type}
              fieldName={criteriaField.name}
              className="inline-flex max-w-full items-center truncate rounded-md bg-subtle p-1 align-middle leading-none text-emphasis group-hover:bg-muted"
              data-testid="rule-card-validation-rule-criteria-field"
            />{' '}
            <span data-testid="rule-card-validation-rule-criteria-operator" className="mr-1">
              {t(`operators.${snakeCase(criteria.operator)}`)}
            </span>
            <ValidationRuleCriteriaValue
              dataTestId="rule-card-validation-rule-criteria-value"
              criteria={criteria}
              criteriaField={criteriaField}
            />
            {index !== rule.criteria.length - 1 && (
              <div className="my-2">{t('components.rules.and')}</div>
            )}
          </Fragment>
        );
      })}
      <p className="mt-2">{t('components.validation_rule_card.message_label')}</p>
      <p className="mt-4 truncate text-emphasis">{rule.message}</p>
      {children}
    </Card>
  );
}
