import { useCallback } from 'react';

import { type BuilderPage, type BuilderPageKey } from '@/types/schema/BuilderPage';
import { type KnackObject, type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

type EligibleLinkablePage = {
  label: string;
  slug: string;
  key: BuilderPageKey;
};

export function usePageHelpers() {
  const { data: pages = [] } = usePagesQuery();
  const { data: application } = useApplicationQuery();

  const { getUserRoleObjectFromProfileKey } = useObjectHelpers();

  const getPageBySlug = (slug: string) => pages.find((page) => page.slug === slug);

  const getPageRoleObjects = (page: BuilderPage, includeAccountsObject = false) => {
    const roleObjects: KnackObject[] = [];

    const allowedProfileKeys: KnackObjectProfileKey[] = page.allowedProfileKeys || [];

    if (includeAccountsObject) {
      allowedProfileKeys.push('all_users');
    }

    allowedProfileKeys.forEach((profileKey) => {
      const roleObject = getUserRoleObjectFromProfileKey(profileKey);

      if (roleObject) {
        roleObjects.push(roleObject);
      }
    });

    return roleObjects;
  };

  // Sort pages by type, with user pages at the end
  const getSortedPages = useCallback(
    (pagesToSort: BuilderPage[]) =>
      pagesToSort.sort((a, b) => {
        if (a.type === b.type) {
          return 0;
        }

        if (a.type === 'user' && b.type !== 'user') {
          return 1;
        }

        return -1;
      }),
    []
  );

  // The start pages are:
  //   - The root pages that are not login or menu pages
  //   - The top child pages of login and menu pages
  const getStartPages = useCallback(() => {
    let startPages: BuilderPage[] = [];

    // Recursive function to get the start pages of a login page or menu page
    function getStartPagesFromLoginOrMenu(page: BuilderPage) {
      if (page.type !== 'menu' && page.type !== 'authentication') {
        return [];
      }

      let loginPageStartPages: BuilderPage[] = [];

      pages.forEach((potentialStartPage) => {
        // Ignore itself, and any top level pages
        if (
          page.key === potentialStartPage.key ||
          (!potentialStartPage.parentSlug && !potentialStartPage.menuPageKey)
        ) {
          return;
        }

        const isChildPage =
          page.type === 'menu'
            ? potentialStartPage.menuPageKey === page.key
            : potentialStartPage.parentSlug === page.slug;

        if (isChildPage) {
          if (potentialStartPage.type === 'page' && !potentialStartPage.sourceObjectKey) {
            loginPageStartPages.push(potentialStartPage);
          }

          if (potentialStartPage.type === 'authentication') {
            loginPageStartPages = loginPageStartPages.concat(
              getStartPagesFromLoginOrMenu(potentialStartPage)
            );
          }
        }
      });

      return loginPageStartPages;
    }

    pages.forEach((page) => {
      // Ignore the non-top level pages
      if (page.parentSlug || page.menuPageKey) {
        return;
      }

      if (page.type === 'authentication') {
        startPages = startPages.concat(getStartPagesFromLoginOrMenu(page));
        return;
      }

      if (page.type === 'menu') {
        startPages = startPages.concat(getStartPagesFromLoginOrMenu(page));
        return;
      }

      if (!page.parentSlug && !page.menuPageKey) {
        startPages.push(page);
      }
    });

    return getSortedPages(startPages);
  }, [getSortedPages, pages]);

  // Get the pages that are eligible to be selected for link/redirection, based on an object key
  // For instance, "Action Links", "Form Submit Rules" and "Page Rules" use this to find eligible pages to link/redirect to.
  const getElegibleLinkablePagesFromObjectKey = useCallback(
    (objectKey: string) => {
      // Recursive function to get the children of a page
      function addChildPages(
        initialEligibleLinkablePages: EligibleLinkablePage[],
        page: BuilderPage,
        label: string
      ) {
        const childPages = pages.filter(
          (p) => p.parentSlug === page.slug || p.menuPageKey === page.key
        );

        childPages.forEach((childPage) => {
          const childLabel = `${label} > ${childPage.name}`;

          if (!childPage.sourceObjectKey || childPage.sourceObjectKey === objectKey) {
            initialEligibleLinkablePages.push({
              label: childLabel,
              slug: childPage.slug,
              key: childPage.key
            });

            if (!childPage.sourceObjectKey) {
              addChildPages(initialEligibleLinkablePages, childPage, childLabel);
            }
          }
        });
      }

      const eligibleLinkablePages: EligibleLinkablePage[] = [];

      getStartPages().forEach((startPage) => {
        eligibleLinkablePages.push({
          label: startPage.name,
          slug: startPage.slug,
          key: startPage.key
        });

        addChildPages(eligibleLinkablePages, startPage, startPage.name);
      });

      return eligibleLinkablePages;
    },
    [getStartPages, pages]
  );

  // Get the pages eligible to link to, ignoring pages that have a source object.
  // For instance, Menu view links use this to find eligible pages to link to, if the page doesn't have a source object.
  const getElegibleLinkablePagesWithoutSourceObject = useCallback(() => {
    // Recursive function to get the children of a page
    function addChildPages(
      initialEligibleLinkablePages: EligibleLinkablePage[],
      page: BuilderPage,
      label: string
    ) {
      const childPages = pages.filter(
        (p) => p.parentSlug === page.slug || p.menuPageKey === page.key
      );

      childPages.forEach((childPage) => {
        if (childPage.sourceObjectKey) {
          return;
        }

        const childLabel = `${label} > ${childPage.name}`;

        initialEligibleLinkablePages.push({
          label: childLabel,
          slug: childPage.slug,
          key: childPage.key
        });

        addChildPages(initialEligibleLinkablePages, childPage, childLabel);
      });
    }

    const eligibleLinkablePages: EligibleLinkablePage[] = [];

    getStartPages().forEach((startPage) => {
      eligibleLinkablePages.push({
        label: startPage.name,
        slug: startPage.slug,
        key: startPage.key
      });

      if (startPage.sourceObjectKey) {
        return;
      }

      addChildPages(eligibleLinkablePages, startPage, startPage.name);
    });

    return eligibleLinkablePages;
  }, [getStartPages, pages]);

  // Find and return the closest login page of a page. If the page is already a login page, return itself
  const getClosestLoginPage = useCallback(
    (page: BuilderPage) => {
      if (page.type === 'authentication') {
        return page;
      }

      let parentPageSlug = page.parentSlug;

      while (parentPageSlug) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        const parentPage = pages.find((p) => p.slug === parentPageSlug);

        if (!parentPage) {
          break;
        }

        if (parentPage.type === 'authentication') {
          return parentPage;
        }

        parentPageSlug = parentPage.parentSlug;
      }

      return null;
    },
    [pages]
  );

  // A page is considered top level if it doesn't have a page parent or menu parent. Unlike start pages, top level pages include login pages
  const isTopLevelPage = useCallback(
    (page: BuilderPage) => {
      if (!application) {
        return false;
      }

      // If the application login is global, there are different things that determine if a page is considered top level
      if (application.users.scope === 'application') {
        // User pages work differently than other pages for global login pages since they aren't children of the global login page
        if (page.type === 'user') {
          return !page.parentSlug;
        }

        // If the page doesn't have a parent slug, it means it's the global login page which is not considered a top level page
        if (!page.parentSlug) {
          return false;
        }

        const parentPage = pages.find((p) => p.slug === page.parentSlug);
        const grandParentPage = pages?.find((p) => p.slug === parentPage?.parentSlug);

        // If a child of the login page has no grandparent, it's considered a top level page
        if (!grandParentPage) {
          return true;
        }

        const grandParentParentPage = pages.find((p) => p.slug === grandParentPage.parentSlug);

        // If a child of the login page has a grandparent, but the grandparent has no parent, it's considered a top level page
        return parentPage?.type === 'menu' && !grandParentParentPage;
      }

      return !page.parentSlug;
    },
    [application, pages]
  );

  // An app will have a global login page if the user logins are scoped to the entire application
  const isGlobalLoginPage = useCallback(
    (page: BuilderPage) => {
      if (!application) {
        return false;
      }

      if (application.users.scope === 'application') {
        return page.type === 'authentication' && !page.parentSlug;
      }

      return false;
    },
    [application]
  );

  const isPageValidForAddingLogin = useCallback(
    (page: BuilderPage) => {
      if (!application) {
        return false;
      }

      if (page.type === 'menu' || page.type === 'authentication' || page.type === 'user') {
        return false;
      }

      const parentPage = pages.find((p) => p.slug === page.parentSlug);

      // Child pages of the global login page can still have their own login page
      if (!parentPage || isGlobalLoginPage(parentPage)) {
        return true;
      }

      // A page can't have a login page if the direct parent is already a login page
      if (page.requiresAuthentication && parentPage.type === 'authentication') {
        return false;
      }

      return true;
    },
    [application, isGlobalLoginPage, pages]
  );

  return {
    getPageBySlug,
    getPageRoleObjects,
    getStartPages,
    getClosestLoginPage,
    getElegibleLinkablePagesFromObjectKey,
    getElegibleLinkablePagesWithoutSourceObject,
    isGlobalLoginPage,
    isTopLevelPage,
    isPageValidForAddingLogin
  };
}
