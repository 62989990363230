import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Button, DropdownMenu, Label } from '@knack/asterisk-react';

import { useApplication } from '@/hooks/useApplication';
import { getValidTablesForImport } from '@/utils/tables';
import { type Column } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';

export function ConnectionSubFields({
  column,
  isColumnVisible
}: {
  column: Column;
  isColumnVisible: boolean;
}) {
  const [t] = useTranslation();
  const application = useApplication();
  const { columns, setColumns, existingTable } = useImportStore((state) => ({
    columns: state.columns,
    existingTable: state.existingTable,
    setColumns: state.setColumns
  }));

  const tables = useMemo(() => getValidTablesForImport(application), [application]);
  const [selectedTable, setSelectedTable] = useState(() => {
    const connectedTableKey = column.meta.existingKnackField?.relationship?.object;
    return tables.find((table) => table.key === connectedTableKey) || tables[0];
  });
  const [selectedField, setSelectedField] = useState(() => selectedTable?.fields?.[0]);
  const [ifNoMatch, setIfNoMatch] = useState<'skip' | 'insert'>('skip');

  const matchOptions = {
    skip: t('components.add_table.skip_this_value', { column: column.header }),
    insert: t('components.add_table.insert_new_connection', {
      column: column.header,
      selectedTable: selectedTable?.name
    })
  };

  const updateColumns = useCallback(() => {
    const updatedColumns = columns.map((currentColumn) => {
      let updatedColumn = currentColumn;

      if (currentColumn.header === column.header) {
        updatedColumn = {
          ...currentColumn,
          meta: {
            ...currentColumn.meta,
            connectionMatchField: selectedField?.key,
            connectionObjectKey: selectedTable?.key,
            connectionNoMatchRule: ifNoMatch
          }
        };
      }

      // reset the column connection if it's not visible
      if (!isColumnVisible) {
        delete updatedColumn.meta.connectionMatchField;
        delete updatedColumn.meta.connectionObjectKey;
        delete updatedColumn.meta.connectionNoMatchRule;
      }

      return updatedColumn;
    });

    setColumns(updatedColumns);
  }, [
    columns,
    setColumns,
    column.header,
    isColumnVisible,
    selectedField?.key,
    selectedTable?.key,
    ifNoMatch
  ]);

  useEffect(() => {
    if (tables) {
      updateColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField, selectedTable, ifNoMatch, isColumnVisible]);

  useEffect(() => {
    if (column.meta.existingKnackField) {
      const connectedTableKey = column.meta.existingKnackField?.relationship?.object;
      const connectedTable = tables.find((table) => table.key === connectedTableKey);

      if (!selectedTable || selectedTable.key !== connectedTableKey) {
        const defaultTable = connectedTable || tables[0];
        setSelectedTable(defaultTable);
        setSelectedField(defaultTable?.fields[0]);
      }
    }
  }, [column, selectedTable, tables]);

  useEffect(() => {
    if (selectedTable) {
      setSelectedField(selectedTable.fields[0]);
    }
  }, [selectedTable]);

  if (!isColumnVisible) return null;

  return (
    <div className="mb-3 ml-6 space-y-3">
      <div>
        {(!existingTable || !column.meta.existingKnackField) && (
          <div className="mb-2 space-y-3">
            <Label className="ml-2" data-testid="subfield-connection-label">
              {t('components.add_table.which_table_to_connect')}
            </Label>
            <DropdownMenu>
              <DropdownMenu.Trigger asChild>
                <Button
                  size="sm"
                  intent="secondary"
                  className="w-full justify-between"
                  data-testid="subfield-connection-select-table-button"
                >
                  {selectedTable?.name}
                  <ChevronDownIcon size={16} />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                align="end"
                data-testid="subfield-connection-select-table-content"
                collisionPadding={8}
              >
                {tables?.map((tableToMap) => (
                  <DropdownMenu.Item
                    key={tableToMap.key}
                    onSelect={() => {
                      setSelectedTable(tableToMap);
                      setSelectedField(tableToMap.fields[0]);
                    }}
                  >
                    {tableToMap.name}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu>
          </div>
        )}
      </div>
      <div className="mb-2">
        <Label className="ml-2">
          <Trans
            i18nKey="components.add_table.which_field_to_connect"
            values={{ selectedTable: selectedTable?.name, column: column.header }}
          >
            <span className="font-semibold">{selectedTable?.name}</span>
            <span className="font-semibold">{column.header}</span>
          </Trans>
        </Label>
      </div>
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button
            size="sm"
            intent="secondary"
            className="w-full justify-between"
            data-testid="subfield-connection-select-field-button"
          >
            {selectedField?.name}
            <ChevronDownIcon size={16} />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          align="end"
          data-testid="subfield-connection-select-field-content"
          collisionPadding={8}
        >
          {selectedTable?.fields?.map((field) => (
            <DropdownMenu.Item
              key={field.key}
              onSelect={() => {
                setSelectedField(field);
              }}
            >
              {field.name}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
      <div className="mb-2">
        <Label className="ml-2">{t('components.add_table.what_if_no_match')}</Label>
      </div>
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button
            size="sm"
            intent="secondary"
            className="w-full justify-between"
            data-testid="subfield-connection-select-matching-button"
          >
            {ifNoMatch === 'skip' ? matchOptions.skip : matchOptions.insert}
            <ChevronDownIcon size={16} />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end" data-testid="subfield-connection-select-matching-content">
          <DropdownMenu.Item
            onSelect={() => {
              setIfNoMatch('skip');
            }}
          >
            {matchOptions.skip}
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => {
              setIfNoMatch('insert');
            }}
          >
            {matchOptions.insert}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  );
}
