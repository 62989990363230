import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';
import { truncate } from 'lodash';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { parseHTML } from '@/utils/parser';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';

const MAX_NUMBER_OF_EMAILS = 4;
const MAX_CHARACTERS_IN_EMAIL_MESSAGE = 100;

export function ViewRecordRuleCardEmailContent({
  recordRule,
  setIsEditRuleModalOpen
}: {
  recordRule: RecordRule;
  setIsEditRuleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [t] = useTranslation();
  const { getFieldByKey } = useFieldHelpers();

  if (!recordRule.email) return null;

  const recipientsEmails = recordRule.email.recipients.reduce<
    { email: string; field?: KnackField }[]
  >((acc, recipient) => {
    if (recipient.recipient_type === 'custom') {
      acc.push({ email: recipient.email });
    } else {
      let field: KnackField | undefined;

      if (recipient.field) {
        field = getFieldByKey(recipient.field);
      }
      if (!field && recipient.field) {
        const fieldKey = recipient.field.split('-')[1] as KnackFieldKey;
        field = getFieldByKey(fieldKey);
      }

      if (field) {
        acc.push({ email: '', field });
      }
    }

    return acc;
  }, []);

  return (
    <div className="space-y-1">
      <p>{t('components.record_rule_card.then_send_email_to')}</p>
      <div className="flex flex-wrap items-center gap-1">
        {recipientsEmails.map((email, index) => {
          const isMaxNumberOfEmailsReached = index === MAX_NUMBER_OF_EMAILS;

          if (index > MAX_NUMBER_OF_EMAILS) {
            return null;
          }

          if (isMaxNumberOfEmailsReached) {
            return (
              <Button
                key="max-emails"
                intent="link"
                className="items-center focus:bg-transparent active:bg-transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditRuleModalOpen(true);
                }}
              >
                + {(recordRule.email?.recipients?.length || 0) - MAX_NUMBER_OF_EMAILS}{' '}
                {t('keywords.more')}
              </Button>
            );
          }

          if (email.field) {
            return (
              <InlineKnackField
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                fieldName={email.field.name}
                fieldType={email.field.type}
                className="border-none bg-action"
              />
            );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <InlineKnackValue value={email.email} className="border-none bg-action" key={index} />
          );
        })}
      </div>
      <div>
        <p>{t('components.record_rule_card.with_message')}</p>
        <p className="text-emphasis">
          {truncate(parseHTML(recordRule.email.message), {
            length: MAX_CHARACTERS_IN_EMAIL_MESSAGE,
            omission: '...'
          })}
        </p>
      </div>
    </div>
  );
}
