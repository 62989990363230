import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { MainLayout } from '@/components/layout/MainLayout';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { SettingsSectionTree } from './SettingsSectionTree';
import { SettingsTopBar } from './SettingsTopBar';

function SettingsPageMainContent() {
  return (
    <div className="p-6">
      <SettingsTopBar />
      <Outlet />
    </div>
  );
}

export function SettingsPage() {
  const [t] = useTranslation();

  return (
    <MainLayout>
      <ResizableCollapsiblePanel
        title={t('components.layout.left_sidebar.settings')}
        panelContent={<SettingsSectionTree />}
        mainContent={<SettingsPageMainContent />}
        autoSaveId="app-settings-panel"
      />
    </MainLayout>
  );
}
