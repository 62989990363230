import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Input,
  Label,
  RadioGroup,
  Spinner,
  Textarea,
  useToast
} from '@knack/asterisk-react';
import { z } from 'zod';

import { useReportBugMutation } from '@/hooks/api/mutations/useReportBugMutation';
import { useErrorToast } from '@/hooks/useErrorToast';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';

export function ReportBugForm() {
  const { resetPanel } = useHelpPanelStore((state) => ({
    resetPanel: state.resetPanel
  }));
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const presentErrorToast = useErrorToast();
  const { mutate, isPending } = useReportBugMutation();

  const reportBugFormSchema = z.object({
    bugLocation: z.enum(['Builder', 'Interface']),
    bugUrl: z.string(),
    bugDescription: z.string()
  });

  type ReportBugFormSchema = z.infer<typeof reportBugFormSchema>;

  const {
    register,
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors }
  } = useForm<ReportBugFormSchema>({
    resolver: zodResolver(reportBugFormSchema),
    defaultValues: {
      bugUrl: '',
      bugDescription: ''
    }
  });

  const onSubmitHandler: SubmitHandler<ReportBugFormSchema> = (data) => {
    mutate(data, {
      onSuccess: () => {
        resetPanel();
        presentToast({
          title: t('components.help_menu.forms.report_bug.message_success')
        });
      },
      onError: (error) => {
        presentErrorToast({
          error,
          translationPrefix: 'components.help_menu.forms.report_bug',
          fallbackKey: 'components.help_menu.forms.report_bug.error_sending_message'
        });
      }
    });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        id="report-bug-form"
        data-testid="report-bug-form"
        className="space-y-4 *:space-y-2"
      >
        <div>
          <Label
            className="font-medium text-default"
            isRequired
            data-testid="report-bug-form-are-you-seeing-label"
          >
            {t('components.help_menu.forms.report_bug.are_you_seeing')}
          </Label>
          <Controller
            control={control}
            name="bugLocation"
            render={({ field }) => (
              <RadioGroup onValueChange={field.onChange} value={field.value} className="mt-2">
                <RadioGroup.Container>
                  <RadioGroup.Item
                    value="Builder"
                    id="builder"
                    data-testid="report-bug-form-builder-option"
                  />
                  <Label htmlFor="builder">
                    {t('components.help_menu.forms.report_bug.builder')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="Interface" id="live-app" />
                  <Label htmlFor="live-app">
                    {t('components.help_menu.forms.report_bug.live_app')}
                  </Label>
                </RadioGroup.Container>
              </RadioGroup>
            )}
          />
          {errors.bugLocation && (
            <p className="text-destructive" data-testid="report-bug-form-error-message">
              {t('components.help_menu.forms.report_bug.this_field_is_required')}
            </p>
          )}
        </div>
        <div>
          <Label isRequired className="font-medium text-default" data-testid="report-bug-form-url">
            {t('components.help_menu.forms.report_bug.what_is_the_url')}
          </Label>
          <Input
            placeholder={t('keywords.url')}
            {...register('bugUrl')}
            data-testid="report-bug-form-url-input"
          />
          {errors.bugUrl && (
            <p className="text-destructive">
              {t('components.help_menu.forms.report_bug.this_field_is_required')}
            </p>
          )}
        </div>
        <div>
          <Label isRequired className="font-medium text-default">
            {t('components.help_menu.forms.report_bug.describe')}
          </Label>
          <p className="text-muted">{t('components.help_menu.forms.report_bug.helper_text')}</p>
          <Textarea
            spellCheck={false}
            {...register('bugDescription')}
            data-testid="report-bug-form-textarea"
          />
          {errors.bugDescription && (
            <p className="text-destructive">
              {t('components.help_menu.forms.report_bug.this_field_is_required')}
            </p>
          )}
        </div>
      </form>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          intent="minimal"
          disabled={isPending}
          onClick={() => resetForm()}
          data-testid="report-bug-form-clear-button"
        >
          {t('actions.clear')}
        </Button>
        <Button
          type="submit"
          form="report-bug-form"
          disabled={isPending}
          data-testid="report-bug-form-submit-button"
        >
          {isPending ? <Spinner /> : t('actions.submit')}
        </Button>
      </div>
    </div>
  );
}
