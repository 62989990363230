import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { type ViewsWithFiltering } from '@/pages/pages/settings-panel/view-settings/common/filtering/types';

interface FilterGroupProps {
  field: KnackField;
  filterRule: KnackFilter;
}

interface ViewSettingsDefaultFiltersCardProps {
  defaultFilters: KnackFilter[];
}

function FilterGroup({ field, filterRule }: FilterGroupProps) {
  return (
    <div className="my-2 rounded-lg bg-subtle p-2">
      <InlineKnackField fieldName={field.name} fieldType={field.type} className="bg-action" />{' '}
      {filterRule.operator}{' '}
      <InlineKnackCriteriaValue criteria={filterRule} criteriaField={field} className="bg-action" />
    </div>
  );
}

export function ViewSettingsDefaultFiltersCard({
  defaultFilters
}: ViewSettingsDefaultFiltersCardProps) {
  const { sourceObject } = useActiveViewContext<ViewsWithFiltering>();

  const getKnackField = (fieldKey: KnackFieldKey) =>
    sourceObject?.fields.find((f) => f.key === fieldKey);

  return (
    <div>
      {defaultFilters.map((rule, ruleIndex) => {
        const knackField = getKnackField(rule.field);

        if (!knackField) {
          return null;
        }

        return (
          <FilterGroup
            // eslint-disable-next-line react/no-array-index-key
            key={`${rule.field}-${ruleIndex}-filter`}
            field={knackField}
            filterRule={rule}
          />
        );
      })}
    </div>
  );
}
