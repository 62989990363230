import { useTranslation } from 'react-i18next';

import { BuilderAccordion } from '@/components/BuilderAccordion';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { MenuLinkSettings } from './link-settings/MenuLinkSettings';
import { MenuLinksSettings } from './MenuLinksSettings';

export function MenuSettings() {
  const [t] = useTranslation();

  const { viewInputId, isViewInputEditMode, setIsViewInputEditMode, setViewInputId } =
    useViewInputEditModeContext();

  const onBackHandler = () => {
    if (viewInputId) {
      setIsViewInputEditMode(false);
      setViewInputId(null);
    }
  };

  if (import.meta.env.PUBLIC_IS_PRODUCTION === 'true') {
    <CollapsiblePanel.Content title={t('pages.element_settings.menu.heading')}>
      {t('keywords.coming_soon')}
    </CollapsiblePanel.Content>;
  }

  return (
    <CollapsiblePanel.Content
      title={isViewInputEditMode ? 'Link Settings' : t('pages.element_settings.menu.heading')}
      onBack={isViewInputEditMode ? onBackHandler : undefined}
    >
      {isViewInputEditMode ? (
        <MenuLinkSettings />
      ) : (
        <BuilderAccordion>
          <ViewTitleDescriptionSettings />
          <MenuLinksSettings />
        </BuilderAccordion>
      )}
    </CollapsiblePanel.Content>
  );
}
