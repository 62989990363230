import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type ConditionalRule } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { cn } from '@/utils/tailwind';
import { InlineKnackField } from '@/components/InlineKnackField';
import { ConditionalRuleActionValue } from '@/pages/data-model/diagram/ConditionalRuleActionValue';
import { ConditionalRuleCriteriaValue } from '@/pages/data-model/diagram/ConditionalRuleCriteriaValue';

interface ConditionalRuleCardProps {
  rule: ConditionalRule;
  table: KnackObject;
  children?: React.ReactNode;
  className?: string;
}

export function ConditionalRuleCard({
  rule,
  table,
  children,
  className
}: ConditionalRuleCardProps) {
  const [t] = useTranslation();
  const ruleClasses =
    'inline-flex max-w-sm items-center truncate rounded-md bg-subtle p-1 align-middle leading-none text-emphasis group-hover:bg-muted';
  const knackFieldClasses =
    'inline-flex items-center rounded-md bg-subtle p-1 align-middle leading-none text-emphasis group-hover:bg-muted';
  const ruleWrapperClasses = 'max-w-full overflow-hidden rounded-md';

  const valueField = table.fields.find((f) => f.key === rule.values[0].field);
  if (!valueField) return null;

  return (
    <Card
      key={rule.key}
      className={cn('bg-muted p-4 shadow-none sm:p-4', className)}
      data-testid={`conditional-rule-card-${rule.key}`}
    >
      {rule.criteria.length > 0 ? (
        <>
          <span>{t('components.rules.when')}</span>
          {rule.criteria.map((criteria, index) => {
            const criteriaField = table.fields.find((f) => f.key === criteria.field);
            if (!criteriaField) return null;
            return (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={`${criteria.field}-${index}`}
                data-testid={`conditional-rule-card-criteria-value-${index}`}
              >
                {' '}
                <InlineKnackField
                  fieldType={criteriaField.type}
                  fieldName={criteriaField.name}
                  className={knackFieldClasses}
                />{' '}
                <span>{t(`operators.${snakeCase(criteria.operator)}`)}</span>{' '}
                <div className={ruleWrapperClasses}>
                  <ConditionalRuleCriteriaValue
                    criteria={criteria}
                    tableFields={table.fields}
                    className={ruleClasses}
                  />
                </div>
                {index < rule.criteria.length - 1 && (
                  <div className="my-2">{t('components.rules.and')}</div>
                )}
              </span>
            );
          })}
        </>
      ) : (
        <span>
          {t('components.data_table.right_sidebar.conditional_rules.run_with_every_record')},{' '}
        </span>
      )}

      <div data-testid="conditional-rule-card-value" className="mt-2 max-w-full">
        <span>
          {t('components.data_table.right_sidebar.conditional_rules.set_the_value_of')}{' '}
          <InlineKnackField
            fieldType={valueField.type}
            fieldName={valueField.name}
            className={knackFieldClasses}
            data-testid="conditional-rule-base-field"
          />{' '}
          {rule.values.map((ruleValues, valuesIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={valuesIndex} data-testid={`conditional-rule-card-value-${valuesIndex}`}>
              {ruleValues.type === 'record' ? (
                <span>
                  {t('components.data_table.right_sidebar.conditional_rules.to_the_value_of')}{' '}
                </span>
              ) : (
                <span> {t('components.data_table.right_sidebar.conditional_rules.to')} </span>
              )}
              <div className={ruleWrapperClasses}>
                <ConditionalRuleActionValue
                  ruleValues={ruleValues}
                  field={valueField}
                  tableFields={table.fields}
                  className={ruleClasses}
                />
              </div>
            </span>
          ))}
        </span>
      </div>
      {children}
    </Card>
  );
}
