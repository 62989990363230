import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { HiTrash as RemoveIcon } from 'react-icons/hi2';
import { Button, Divider, Input, Label } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import type { ReportView, ReportViewChartGroup } from '@/types/schema/views/ReportView';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { getGroupingCategoriesOptions } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/reportViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { type SortingOption } from '@/pages/pages/settings-panel/view-settings/common/sorting/helper';
import { CategorySelector } from '@/pages/pages/settings-panel/view-settings/report/data-display/CategorySelector';
import { ReportFiltersDialog } from '@/pages/pages/settings-panel/view-settings/report/data-display/filters/ReportDataDisplayFiltersDialog';
import { ReportFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/report/data-display/filters/ReportFiltersGroupsCard';
import { CategoryGroupDateBy } from '@/pages/pages/settings-panel/view-settings/report/data-display/sorting/CategoryGroupDateBy';
import { CategorySortSelector } from '@/pages/pages/settings-panel/view-settings/report/data-display/sorting/CategorySortSelector';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ReportDataDisplayCategory() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();
  const { getObjectByKey } = useObjectHelpers();

  const { groups, calculations } = view.rows[0].reports[0];

  const defaultGroup: ReportViewChartGroup = {
    group: `${sourceObject.fields[0].type}-${sourceObject.fields[0].key}`,
    type: sourceObject.fields[0].type,
    field: sourceObject.fields[0].key,
    label: sourceObject.fields[0].name,
    sort: 'asc'
  };
  const isPieChart = view.rows[0].reports[0].type === 'pie';

  const availableCategories = getGroupingCategoriesOptions(sourceObject);

  const getConnectedTableFromCategoryField = (fieldKey: KnackFieldKey) => {
    const allSourceObjectConnections = [
      ...sourceObject.connections.inbound,
      ...sourceObject.connections.outbound
    ];

    const connectedFieldConnection = allSourceObjectConnections.find(
      (connection) => connection.key === fieldKey
    );

    if (!connectedFieldConnection) return null;

    return getObjectByKey(connectedFieldConnection.object);
  };

  const shouldShowConnectionFilters = (group: ReportViewChartGroup) =>
    group.type === 'connection' && !!getConnectedTableFromCategoryField(group.field);

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t('pages.element_settings.report.categories.data_display.x_axis')}
    >
      {groups.map((group, index) => (
        <BuilderAccordion.Item
          key={group.field}
          isDefaultOpen
          label={t('pages.element_settings.report.categories.data_display.group_n', {
            count: index + 1
          })}
          className="mb-4 rounded-xl bg-subtle p-2"
          removeItemButton={
            groups.length > 1 ? (
              <Button
                intent="minimal"
                onClick={() => {
                  const newSchema = getBaseReportViewObject(view);
                  newSchema.rows[0].reports[0].groups.splice(index, 1);
                  updateViewSchema(newSchema);
                }}
              >
                <RemoveIcon className="text-subtle" />
              </Button>
            ) : undefined
          }
        >
          <div className="mb-4">
            <Label className="mb-2 block" htmlFor="category-label">
              {t('pages.element_settings.report.categories.data_display.label')}
            </Label>
            <Input
              id="category-label"
              value={group.label}
              onChange={(e) => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].groups[index].label = e.target.value;
                updateViewSchema(newSchema);
              }}
            />
          </div>
          <div className="mb-4">
            <CategorySelector
              id={`category-selector-${index}`}
              availableCategories={availableCategories}
              onSelectCategory={(categorySelected) => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].groups[index] = {
                  group: categorySelected.value,
                  field: categorySelected.key || defaultGroup.field,
                  label: categorySelected.label,
                  type: categorySelected.type,
                  sort: 'asc'
                };
                updateViewSchema(newSchema);
              }}
              defaultValue={group.group}
            />

            <Divider className="mt-4" />
            <CategorySortSelector
              id={`category-sort-selector-${index}`}
              group={group}
              onSelectSortCategory={(value = 'asc') => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].groups[index].sort = value as SortingOption['value'];
                updateViewSchema(newSchema);
              }}
            />
          </div>
          {group.type === 'connection' && shouldShowConnectionFilters(group) && (
            <div className="mt-4 flex flex-col">
              <Label className="mb-2">
                {t('pages.element_settings.report.categories.data_display.filters.filters_label')}
              </Label>
              <div className="space-y-4">
                <ReportFiltersGroupsCard
                  sourceObject={getConnectedTableFromCategoryField(group.field) as KnackObject}
                  filters={group.filters ?? []}
                />
                <ReportFiltersDialog
                  sourceObject={getConnectedTableFromCategoryField(group.field) as KnackObject}
                  filters={group.filters ?? []}
                  onFormSubmit={({ filters }) => {
                    const newSchema = getBaseReportViewObject(view);
                    newSchema.rows[0].reports[0].groups[index].filters = filters;
                    updateViewSchema(newSchema);
                  }}
                />
              </div>
            </div>
          )}
          {group.type === 'date_time' && (
            <CategoryGroupDateBy view={view} group={group} index={index} />
          )}
        </BuilderAccordion.Item>
      ))}
      {!isPieChart && (
        <Button
          intent="secondary"
          disabled={groups.length > 1 || calculations.length > 1}
          onClick={() => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].groups.push(defaultGroup);
            updateViewSchema(newSchema);
          }}
        >
          <PlusIcon size={16} className="mr-1" />
          {t('pages.element_settings.report.categories.data_display.x_axis_group')}
        </Button>
      )}
    </BuilderAccordion.Item>
  );
}
