import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type RecordRule, type RecordRuleValue } from '@/types/schema/rules/RecordRule';
import { useRecordRuleHelpers } from '@/hooks/useRecordRuleHelpers';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';

interface ViewRecordRuleValueInputProps {
  availableFields: KnackField[];
  recordRuleActionType: RecordRuleValue['type'];
  formFieldName: `values.${number}`;
}

export function ViewRecordRuleValueInput({
  availableFields,
  recordRuleActionType,
  formFieldName
}: ViewRecordRuleValueInputProps) {
  const [t] = useTranslation();
  const { getConnectedFieldsFromSourceObjects } = useRecordRuleHelpers();

  const connectionFieldsWithRelationship = getConnectedFieldsFromSourceObjects(availableFields);

  const {
    register,
    getFieldState,
    formState: { errors }
  } = useFormContext<RecordRule>();

  const hasError = getFieldState(`${formFieldName}.value`).error;

  if (recordRuleActionType === 'connection' && connectionFieldsWithRelationship.length) {
    return (
      <Controller
        name={`${formFieldName}.connection_field`}
        render={({ field: { value: fieldKey, onChange } }) => (
          <Select value={fieldKey} onValueChange={onChange}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              {connectionFieldsWithRelationship.map((connectedField) =>
                connectedField.connectionObject.fields.map((field) => (
                  <Select.Item
                    key={`${connectedField.field.key}-${field.key}`}
                    // value has to be like field_204-field_24. The first field is the source connected field and the second field is the field in the connected object
                    value={`${connectedField.field.key}-${field.key}`}
                  >
                    <span className="flex items-center">
                      {`${connectedField.connectionObject.name} (${connectedField.field.name}) > ${field.name}`}
                    </span>
                  </Select.Item>
                ))
              )}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  if (recordRuleActionType === 'record') {
    return (
      <Controller
        name={`${formFieldName}.input`}
        render={({ field: { value: fieldKey, onChange } }) => (
          <Select value={fieldKey} onValueChange={onChange}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              {availableFields.map((field) => (
                <Select.Item key={field.key} value={field.key}>
                  <span className="flex items-center">
                    <FieldIcon className="mr-2 shrink-0 text-subtle" size={16} name={field.type} />
                    {field.name}
                  </span>
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  return (
    <>
      <Input
        placeholder={t('actions.enter_value')}
        intent={hasError ? 'destructive' : undefined}
        {...register(`${formFieldName}.value`)}
      />
      <FormErrorMessage name={`${formFieldName}.value`} errors={errors} className="mt-1" />
    </>
  );
}
