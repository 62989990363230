import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { ROUTES } from '@/Router';

export function SettingsPageGeneralSection() {
  const { pathname } = useLocation();

  const tabsListItems: TabsListItem[] = [
    {
      children: <Link to={ROUTES.SETTINGS_GENERAL_CORE}>Core</Link>,
      value: ROUTES.SETTINGS_GENERAL_CORE
    },
    {
      children: <Link to={ROUTES.SETTINGS_GENERAL_SECURITY}>Security</Link>,
      value: ROUTES.SETTINGS_GENERAL_SECURITY
    },
    {
      children: <Link to={ROUTES.SETTINGS_GENERAL_MAPS}>Map Providers</Link>,
      value: ROUTES.SETTINGS_GENERAL_MAPS
    },
    {
      children: <Link to={ROUTES.SETTINGS_GENERALS_COMPLIANCE}>Data & Compliance</Link>,
      value: ROUTES.SETTINGS_GENERALS_COMPLIANCE
    }
  ];

  return (
    <div>
      <Tabs value={pathname}>
        <Tabs.List
          className="w-auto"
          items={tabsListItems}
          shouldDisableResponsive
          intent="page"
          shouldUseGradient
        />
      </Tabs>
      <Outlet />
    </div>
  );
}
