import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { type TableViewColumn } from '@/types/schema/views/TableView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { ViewRecordRuleCard } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCard';
import { ViewRecordRuleFormDialog } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleFormDialog';

type NewlyInsertedRecordRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};

interface TableRecordRulesProps {
  column: TableViewColumn;
  sourceObject: KnackObject;
  updateViewColumn: (updatedData: Partial<TableViewColumn>) => void;
}

export function TableRecordRules({
  column,
  sourceObject,
  updateViewColumn
}: TableRecordRulesProps) {
  const [t] = useTranslation();

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [isAddNewRuleModalOpen, setIsAddNewRuleModalOpen] = useState(false);
  const [newlyInsertedRule, setNewlyInsertedRule] = useState<NewlyInsertedRecordRule | null>(null);
  const [beingDraggedRuleKey, setBeingDraggedRuleKey] = useState<string | null>(null);

  const totalRecordRulesCount = column.edit_rules?.length ?? 0;

  // Create a fieldMap to quickly access the fields without having to iterate over sourceObject.fields every time.
  const sourceObjectFieldsMap: Record<KnackFieldKey, KnackField> = useMemo(
    () => Object.fromEntries(sourceObject.fields.map((field) => [field.key, field])),
    [sourceObject]
  );

  const onRuleAdd = (newDisplayRule: RecordRule) => {
    setNewlyInsertedRule({
      key: newDisplayRule.key,
      insertAction: 'new'
    });

    updateViewColumn({
      id: column.id,
      edit_rules: column.edit_rules ? [...column.edit_rules, newDisplayRule] : [newDisplayRule]
    });
  };

  const onRuleSave = (updatedRecordRule: RecordRule) => {
    if (!column.edit_rules) return;

    const updatedRules = column.edit_rules.map((rule) =>
      rule.key === updatedRecordRule.key ? updatedRecordRule : rule
    );

    updateViewColumn({
      id: column.id,
      edit_rules: updatedRules
    });
  };

  const getDefaultRecordRule = () => {
    const defaultRecordRule: RecordRule = {
      key: `record_${nanoid(10)}`,
      criteria: [],
      action: 'record',
      values: []
    };

    return defaultRecordRule;
  };

  const onRuleDuplicate = (recordRuleToDuplicate: RecordRule) => {
    if (!column.edit_rules) return;

    const defaultRecordRule = getDefaultRecordRule();

    const duplicatedRule: RecordRule = {
      ...recordRuleToDuplicate,
      key: defaultRecordRule.key
    };

    setNewlyInsertedRule({
      key: duplicatedRule.key,
      insertAction: 'duplicate'
    });

    updateViewColumn({
      id: column.id,
      edit_rules: [...column.edit_rules, duplicatedRule]
    });
  };

  const onRuleDelete = (recordRuleKey: RecordRule['key']) => {
    if (!column.edit_rules) return;

    const newRules = column.edit_rules.filter((rule) => rule.key !== recordRuleKey);

    updateViewColumn({
      id: column.id,
      edit_rules: newRules
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedRuleKey(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!column.edit_rules) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = column.edit_rules.findIndex((rule) => rule.key === active.id) ?? -1;
      const newIndex = column.edit_rules.findIndex((rule) => rule.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const newArray = arrayMove(column.edit_rules, oldIndex, newIndex);

      updateViewColumn({
        id: column.id,
        edit_rules: newArray
      });

      setBeingDraggedRuleKey(null);
    }
  };

  return (
    <>
      <div className="mb-2flex items-center gap-1">
        <span className="text-xs font-medium text-emphasis">
          {t('components.rules.manage_rules')}
        </span>
        <span className="ml-1 rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
          {totalRecordRulesCount}
        </span>
      </div>
      <p className="mb-4 text-xs text-subtle">{t('components.rules.record_rules.description')}</p>
      <Button
        intent="secondary"
        className="w-full"
        onClick={() => setIsAddNewRuleModalOpen(true)}
        data-testid="add-record-rule-button"
      >
        <PlusIcon size={16} className="mr-2" />
        {t('components.rules.add_rule')}
      </Button>

      {column.edit_rules && column.edit_rules.length > 0 && (
        <DndContext
          sensors={optimizedSensors}
          collisionDetection={verticalListCollisionDetection}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={column.edit_rules.map((rule) => rule.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="mt-4 space-y-2">
              {column.edit_rules.map((recordRule, ruleIndex) => (
                <VerticalListSortableItem key={recordRule.key} id={recordRule.key}>
                  <ViewRecordRuleCard
                    sourceObject={sourceObject}
                    recordRule={recordRule}
                    sourceObjectFieldsMap={sourceObjectFieldsMap}
                    ruleNumber={ruleIndex + 1}
                    shouldScrollIntoView={newlyInsertedRule?.key === recordRule.key}
                    onRuleSave={onRuleSave}
                    onRuleDuplicate={onRuleDuplicate}
                    onRuleDelete={onRuleDelete}
                  />
                </VerticalListSortableItem>
              ))}
            </div>
          </SortableContext>

          <DragOverlay>
            {column.edit_rules.map((recordRule, ruleIndex) => {
              if (recordRule.key !== beingDraggedRuleKey) {
                return null;
              }

              return (
                <ViewRecordRuleCard
                  key={recordRule.key}
                  isDragOverlay
                  recordRule={recordRule}
                  sourceObject={sourceObject}
                  sourceObjectFieldsMap={sourceObjectFieldsMap}
                  ruleNumber={ruleIndex + 1}
                  onRuleSave={onRuleSave}
                  onRuleDuplicate={onRuleDuplicate}
                  onRuleDelete={onRuleDelete}
                />
              );
            })}
          </DragOverlay>
        </DndContext>
      )}

      {isAddNewRuleModalOpen && (
        <ViewRecordRuleFormDialog
          sourceObject={sourceObject}
          onOpenChange={setIsAddNewRuleModalOpen}
          recordRule={getDefaultRecordRule()}
          formIntent="add"
          onRuleSave={(newDisplayRule) => {
            onRuleAdd(newDisplayRule);
            setIsAddNewRuleModalOpen(false);
          }}
        />
      )}
    </>
  );
}
