import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { TablesPageLayout } from '@/pages/tables/TablesPageLayout';
import { ROUTES, type PageUrlParams } from '@/Router';

export function RolesPageContent({ isIndex = false }: { isIndex?: boolean }) {
  const urlParams = useParams<PageUrlParams>();
  const { data: app } = useApplicationQuery();

  const isUsersTable = (tableType: KnackObject['type']) => tableType === 'UserObject';

  // Redirect to the All Users table
  const allUsersTable = app?.objects.find((table) => table.profile_key === 'all_users');

  const defaultRedirectPath = allUsersTable
    ? generatePath(ROUTES.ROLES_ID, { id: allUsersTable.key })
    : generatePath(ROUTES.ROLES);

  if (isIndex) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  const { id: tableKey } = urlParams;
  if (!tableKey) {
    throw new Error('No table key provided');
  }

  const table = app?.objects.find((obj) => obj.key === tableKey) || null;

  if (!table) {
    return null;
  }

  if (table.type && !isUsersTable(table.type)) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  return (
    <>
      <Outlet />
      <TablesPageLayout
        objectKey={tableKey as KnackObject['key']}
        data-testid="roles-page-layout"
      />
    </>
  );
}
