import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { LabelPosition, PieType, ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { LegendPlacement } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/LegendPlacement';
import { ShowLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowLabels';
import { ShowTotal } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowTotal';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function PieChartAppearanceSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  const pieTypes: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.filled')}</span>,
      value: 'filled'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.donut')}</span>,
      value: 'donut'
    }
  ];

  const labelPositions: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.inside')}</span>,
      value: 'inside'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.outside')}</span>,
      value: 'outside'
    },
    {
      children: (
        <span>{t('pages.element_settings.report.categories.general.outside_with_line')}</span>
      ),
      value: 'outside_with_line'
    }
  ];

  return (
    <>
      <div className="mb-4">
        <div className="mb-2">{t('pages.element_settings.report.categories.general.style')}</div>
        <Tabs
          defaultValue={view.rows[0].reports[0].layout.pie_type || 'filled'}
          onValueChange={(value) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].layout.pie_type = value as PieType;
            updateViewSchema(newSchema);
          }}
        >
          <Tabs.List items={pieTypes} shouldDisableResponsive />
        </Tabs>
      </div>
      <ShowLabels />
      <div className="mb-4">
        <Tabs
          defaultValue={view.rows[0].reports[0].layout.label_position || 'outside_with_line'}
          onValueChange={(value) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].layout.label_position = value as LabelPosition;
            updateViewSchema(newSchema);
          }}
        >
          <Tabs.List items={labelPositions} shouldDisableResponsive />
        </Tabs>
      </div>
      <ShowTotal />
      <LegendPlacement />
    </>
  );
}
