import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiOutlineDotsVertical as DotsIcon,
  HiPencil as EditIcon,
  HiTrash as RemoveIcon
} from 'react-icons/hi';
import {
  HiSquare2Stack as CopyIcon,
  HiOutlineArrowDownTray as ExportIcon,
  HiPaintBrush as ThemeIcon
} from 'react-icons/hi2';
import { generatePath, Link } from 'react-router-dom';
import {
  AsteriskLogo,
  Badge,
  Button,
  Card,
  Dialog,
  DropdownMenu,
  Tooltip,
  useToast,
  type AppTheme
} from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { useThemeMutation } from '@/hooks/api/mutations/useThemeMutation';
import { Dialogs } from '@/pages/themes/themes-list/Dialogs';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';
import { ROUTES } from '@/Router';

export function ThemeCard({
  theme,
  isActive,
  isDefaultTheme
}: {
  theme: AppTheme;
  isActive: boolean;
  isDefaultTheme?: boolean;
}) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const [currentDialogOpen, setCurrentDialogOpen] = useState<string>('rename');
  const { addMutation, applyMutation } = useThemeMutation();
  const { theming } = useThemesPageContext();
  const isCustomTheme = theme.colors.preset === 'custom';
  // The dynamic color classes are already loaded in TW when generating the appearance color swatches
  let themeMainColor =
    theme.colors.preset === 'default' ? 'bg-brand' : `bg-${theme.colors.preset}-500`;
  if (isCustomTheme) {
    themeMainColor = theme.colors.customTokens?.light.bg['brand-accent'];
  }

  if (!theming) {
    return null;
  }

  const downloadTheme = () => {
    const a = document.createElement('a');
    const exportTheme = { ...theme, id: '' };
    a.href = URL.createObjectURL(new Blob([JSON.stringify(exportTheme)], { type: 'text/json' }));
    a.download = `${theme.name}.json`;
    a.click();
  };

  const duplicateTheme = () => {
    // The theme id gets overwritten in the server, so it doesn't matter if we send it or not
    const newTheme = {
      ...theme,
      name: t('themes.duplicate_theme_name', {
        themeName: theme.name
      }),
      updatedOn: new Date().toISOString()
    };
    addMutation.mutate(
      {
        appTheme: newTheme
      },
      {
        onError: () => {
          presentToast({
            title: t('themes.error_new_theme')
          });
        }
      }
    );
  };

  const applyTheming = () => {
    applyMutation.mutate(
      {
        theming: {
          ...theming,
          activeThemeId: theme.id
        }
      },
      {
        onError: () => {
          presentToast({
            title: t('themes.error_apply_theme')
          });
        }
      }
    );
  };

  const cardContent = (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <Card className="max-h-24 overflow-hidden !p-0 group-hover:bg-subtle">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className={`flex size-24 shrink-0 items-center justify-center p-6 text-white ${isCustomTheme ? '' : themeMainColor}`}
                style={isCustomTheme ? { backgroundColor: themeMainColor } : undefined}
              >
                {theme.id === 'default' ? <AsteriskLogo /> : <ThemeIcon size={60} />}
              </div>
              <div className="line-clamp-3 px-8">
                {theme.name}
                {isActive && (
                  <Badge className="ml-2" intent="success">
                    {t('themes.active')}
                  </Badge>
                )}
                <div className="text-muted">
                  v{theme.version} ·{' '}
                  {theme.updatedOn
                    ? t('themes.udpdated_on', {
                        date: DateTime.fromISO(theme.updatedOn).toFormat('ff')
                      })
                    : t('themes.automatically_updated')}
                </div>
              </div>
            </div>
            <div className="mr-6 flex items-center gap-2">
              {!isActive && (
                <Button
                  intent="secondary"
                  isLoading={applyMutation.isPending}
                  size="sm"
                  onClick={(event) => {
                    applyTheming();
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  {t('actions.apply')}
                </Button>
              )}
              <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                  <Button intent="secondary" size="sm">
                    <Button.Icon icon={DotsIcon} />
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end">
                  <Dialog.Trigger asChild>
                    <DropdownMenu.Item
                      disabled={isDefaultTheme}
                      onSelect={() => setCurrentDialogOpen('rename')}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <EditIcon size={18} className="mr-2" />
                      {t('actions.rename')}
                    </DropdownMenu.Item>
                  </Dialog.Trigger>
                  <DropdownMenu.Item
                    onSelect={downloadTheme}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <ExportIcon size={18} className="mr-2" />
                    {t('actions.export')}
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onSelect={duplicateTheme}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <CopyIcon size={18} className="mr-2" />
                    {t('actions.duplicate')}
                  </DropdownMenu.Item>
                  <>
                    <DropdownMenu.Separator />
                    <Dialog.Trigger asChild>
                      <DropdownMenu.Item
                        disabled={isActive || isDefaultTheme}
                        className="text-destructive"
                        onSelect={() => setCurrentDialogOpen('delete')}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <RemoveIcon size={18} className="mr-2" />
                        {t('actions.delete')}
                      </DropdownMenu.Item>
                    </Dialog.Trigger>
                  </>
                </DropdownMenu.Content>
              </DropdownMenu>
            </div>
          </div>
        </Card>
      </Tooltip.Trigger>
      <Tooltip.Content>
        {isDefaultTheme
          ? t('themes.cannot_edit_tooltip')
          : t('themes.edit_tooltip', {
              themeName: theme.name
            })}
      </Tooltip.Content>
    </Tooltip>
  );

  if (isDefaultTheme) {
    return <Dialog>{cardContent}</Dialog>;
  }

  return (
    <Dialogs theme={theme} currentDialogOpen={currentDialogOpen}>
      <Link className="group" key={theme.id} to={generatePath(ROUTES.THEMES_ID, { id: theme.id })}>
        {cardContent}
      </Link>
    </Dialogs>
  );
}
