import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Input, RichTextEditor } from '@knack/asterisk-react';

import { generateListViewRichTextInput } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/listViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import {
  type ViewWithDetails,
  type ViewWithDetailsInput
} from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldManagement';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface ListSectionBreakInputEditModalProps {
  onOpenChange: (isOpen: boolean) => void;
  existingInput?: ViewWithDetailsInput;
}

export function ViewDetailsRichTextInputEditModal({
  onOpenChange,
  existingInput
}: ListSectionBreakInputEditModalProps) {
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView<ViewWithDetails>();
  const { view } = useActiveViewContext<ViewWithDetails>();

  const [inputSchema, setInputSchema] = useState<ViewWithDetailsInput>(
    () => existingInput || generateListViewRichTextInput()
  );

  const dialogTitle = existingInput
    ? t('pages.element_settings.form.categories.form_fields.field_management.edit_rich_text')
    : t('pages.element_settings.form.categories.form_fields.field_management.add_rich_text');

  const onSave = () => {
    const updatedView: Partial<ViewWithDetails> = {
      columns: view.columns.map((outerColumn) => ({
        ...outerColumn,
        // Add the new static input to the last column in the last group
        groups: outerColumn.groups.map((group, groupIndex) => {
          if (groupIndex === view.columns[0].groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                // If an existing input is provided, we need to update it
                if (existingInput) {
                  return [
                    ...column,
                    ...column.map((input) => {
                      if (input.id === existingInput.id) {
                        return inputSchema;
                      }

                      return input;
                    })
                  ];
                }

                // Otherwise, we add a new rich text input to the last column
                if (columnIndex === group.columns.length - 1) {
                  return [...column, inputSchema];
                }

                return column;
              })
            };
          }
          return group;
        })
      }))
    };

    updateViewSchema(updatedView);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{dialogTitle}</Dialog.Title>
            <Dialog.Description className="sr-only">{dialogTitle}</Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            <div className="mb-4">
              <span className="mb-2 block font-medium">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.rich_text_title'
                )}
              </span>
              <Input
                value={inputSchema.name}
                onChange={(e) => {
                  setInputSchema((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
            </div>
            <div>
              <span className="mb-2 block font-medium">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.rich_text_content'
                )}
              </span>
              <RichTextEditor
                content={inputSchema.copy}
                onUpdate={({ editor }) => {
                  setInputSchema((prev) => ({
                    ...prev,
                    copy: editor.getHTML()
                  }));
                }}
              />
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button onClick={onSave}>{t('actions.add')}</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
