import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import { Badge, Card } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type ConditionalRuleValues } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { cn } from '@/utils/tailwind';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { ConditionalRuleActionValue } from '@/pages/data-model/diagram/ConditionalRuleActionValue';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { TaskConnectedFieldValue } from '@/pages/tables/toolkit-sidebar/tasks/TaskConnectedFieldValue';

type TaskCardProps = {
  task: KnackTask;
  table: KnackObject;
  children?: React.ReactNode;
};

export function TaskCard({ task, table, children }: TaskCardProps) {
  const [t] = useTranslation();

  const isEmailActionTypeTask = task.action.action === 'email';

  const { getFieldByKey } = useFieldHelpers();

  return (
    <Card
      key={task.key}
      className={cn('mt-2 bg-muted p-4 shadow-none group-hover:bg-subtle sm:p-4')}
      data-testid={`connection-card-${task.key}`}
    >
      <div className="flex max-w-[340px] flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <span className="font-semibold">{task.name}</span>
            <Badge intent={task.run_status === 'running' ? 'success' : 'destructive'}>
              {t(
                `components.data_table.right_sidebar.tasks.card.run_status_options.${task.run_status}`
              )}
            </Badge>
          </div>
          <span className="text-xs">
            {`${t(`components.data_table.right_sidebar.tasks.card.action_options.${task.action.action}`)} 
          ${t(`components.data_table.right_sidebar.tasks.card.schedule_options.${task.schedule.repeat}`)}`}
          </span>
        </div>

        <div className="flex flex-col gap-1">
          {task.action.criteria.length === 0 && (
            <span>{t('components.data_table.right_sidebar.tasks.card.for_all_records')}</span>
          )}

          {task.action.criteria.map((criteria, index) => {
            const criteriaField = table.fields.find((f) => f.key === criteria.field);
            const isLastCriteria = index === task.action.criteria.length - 1;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${criteria.field}-${index}`}>
                <span className="mb-2">{t('components.rules.when')}</span>

                {criteriaField ? (
                  <div>
                    <InlineKnackField
                      fieldType={criteriaField.type}
                      fieldName={criteriaField.name}
                      className="border-none bg-subtle group-hover:bg-muted"
                    />{' '}
                    {t(`operators.${snakeCase(criteria.operator)}`)}{' '}
                    <InlineKnackCriteriaValue
                      criteria={criteria}
                      criteriaField={criteriaField}
                      className="bg-subtle group-hover:bg-muted"
                    />
                  </div>
                ) : (
                  <EmptyRuleValue />
                )}

                {!isLastCriteria && <div className="my-1">{t('components.rules.and')}</div>}
              </Fragment>
            );
          })}

          {task.action.criteria.length > 0 && (
            <span className="my-2">{t('components.rules.then')}</span>
          )}

          {task.action.values.length === 0 && <EmptyRuleValue />}

          {isEmailActionTypeTask && t('components.data_table.right_sidebar.tasks.card.send_email')}

          {!isEmailActionTypeTask &&
            task.action.values.map((ruleValues, valuesIndex) => {
              const valueField = getFieldByKey(ruleValues.field);
              const isLastValue = valuesIndex === task.action.values.length - 1;

              if (!valueField) return null;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${ruleValues.field}-${valuesIndex}`}>
                  {t('components.data_table.right_sidebar.tasks.card.set_the_value_of')}
                  <span>
                    <InlineKnackField
                      fieldType={valueField.type}
                      fieldName={valueField.name}
                      className="border-none bg-subtle group-hover:bg-muted"
                      data-testid="task-value-base-field"
                    />
                  </span>
                  {ruleValues.type === 'record' || ruleValues.type === 'connection' ? (
                    <span>
                      {t('components.data_table.right_sidebar.tasks.card.to_the_value_of')}
                    </span>
                  ) : (
                    <span> {t('keywords.to')} </span>
                  )}
                  {ruleValues.type !== 'connection' && (
                    <div className="max-w-full overflow-hidden rounded-md">
                      <ConditionalRuleActionValue
                        ruleValues={ruleValues as ConditionalRuleValues}
                        field={valueField}
                        tableFields={table.fields}
                        className="inline-flex max-w-sm items-center truncate rounded-md bg-subtle p-1 align-middle leading-none text-emphasis group-hover:bg-muted"
                      />
                    </div>
                  )}
                  {ruleValues.type === 'connection' && (
                    <div className="max-w-full overflow-hidden rounded-md">
                      <TaskConnectedFieldValue
                        ruleValueConnectionField={ruleValues.connection_field}
                      />
                    </div>
                  )}
                  {!isLastValue && <div className="my-1">{t('components.rules.and')}</div>}
                </Fragment>
              );
            })}
        </div>
      </div>
      {children}
    </Card>
  );
}
