import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiChevronDown as ChevronDownIcon,
  HiArrowSmallRight as RightArrowIcon
} from 'react-icons/hi2';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  DropdownMenu,
  Label,
  Switch
} from '@knack/asterisk-react';
import { produce } from 'immer';

import { cn } from '@/utils/tailwind';
import { DateTimeFormat } from '@/components/import/DateTimeFormat';
import { ConnectionSubFields } from '@/components/import/subfields/ConnectionSubFields';
import { type Column, type FieldPart } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import { HEADER_HEIGHT } from '@/components/layout/Header';
import { FieldTypeDropdown } from './field-type-dropdown/FieldTypeDropdown';
import { SubFields } from './subfields/SubFields';

export function MapColumns() {
  const [t] = useTranslation();
  const {
    hasHeaderRow,
    columnVisibility,
    columns,
    existingTable,
    getTotalVisibleColumns,
    setHasHeaderRow,
    getColumnVisibility,
    setColumnVisibility,
    setColumnsVisibility,
    setColumns,
    getSheetNames,
    setSelectedSheetIndex,
    getSelectedSheetIndex
  } = useImportStore((state) => ({
    hasHeaderRow: state.hasHeaderRow,
    columnVisibility: state.columnVisibility,
    columns: state.columns,
    existingTable: state.existingTable,
    getTotalVisibleColumns: state.getTotalVisibleColumns,
    setHasHeaderRow: state.setHasHeaderRow,
    setColumnVisibility: state.setColumnVisibility,
    setColumnsVisibility: state.setColumnsVisibility,
    getColumnVisibility: state.getColumnVisibility,
    setColumns: state.setColumns,
    getSheetNames: state.getSheetNames,
    setSelectedSheetIndex: state.setSelectedSheetIndex,
    getSelectedSheetIndex: state.getSelectedSheetIndex
  }));
  const [isAllFieldsSelected, setIsAllFieldsSelected] = useState(true);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  if (getSheetNames().length === 0) {
    return null;
  }

  if (!columns) {
    return null;
  }
  function handleSheetSelection(sheetIndex: number): void {
    setSelectedSheetIndex(sheetIndex);
  }

  const showTopCard = getSheetNames().length > 1;

  const getColumnPartsReset = (column: Column): FieldPart[] =>
    column.meta.parts.length > 0
      ? column.meta.parts.map((part) => ({ ...part, mappedColumnIndex: undefined, mapped: false }))
      : [];

  // If we pass index, we update only one column
  const getUpdatedColumns = (cols: Column[], index?: number): Column[] => {
    const mappedColumns: number[] = [];
    return produce(cols, (draftColumns) => {
      const updateColumn = (column: Column) => {
        if (column.meta.parts) {
          column.meta.parts.forEach((part) => {
            if (part.mapped && part.mappedColumnIndex) mappedColumns.push(part.mappedColumnIndex);
          });
        }
        column.meta.isThisColumnMapped = false;
        column.meta.mappedColumnIndex = undefined;
        column.meta.parts = getColumnPartsReset(column);
        column.meta.existingKnackField = undefined;
      };

      if (index !== undefined) {
        const currentColumn = draftColumns[index];
        if (currentColumn) {
          updateColumn(currentColumn);
        }
        // If the current column had mapped parts, we must reset these columns pointed by the mapped parts
        if (mappedColumns.length > 0) {
          mappedColumns.forEach((col) => {
            updateColumn(draftColumns[col]);
          });
        }
      } else {
        draftColumns.forEach((column) => {
          updateColumn(column);
        });
      }
      mappedColumns.forEach((mappedColumnIndex) => {
        draftColumns[mappedColumnIndex].meta.isThisColumnMapped = false;
      });
      setColumns(draftColumns);
    });
  };

  const onColumnVisibilityChange = (index: number) => {
    setColumnVisibility(index);

    if (!columnVisibility[index]) return;

    const updatedColumns = getUpdatedColumns(columns, index);

    setColumns(updatedColumns);
  };

  const onSelectUnSelectAllColumns = () => {
    const columnIndices = new Array(columns.length).fill(!isAllFieldsSelected);

    setColumnsVisibility(columnIndices);
    setIsAllFieldsSelected(!isAllFieldsSelected);

    const updatedColumns = getUpdatedColumns(columns);

    setColumns(updatedColumns);
  };

  return (
    <div className="mr-6 flex flex-none flex-col gap-3" data-testid="import-columns-section">
      {showTopCard && (
        <Card
          className="flex flex-col gap-3 rounded bg-card shadow-md sm:p-5"
          data-testid="select-sheet-card"
        >
          <Label>{t('components.add_table.choose_tab_to_import')}</Label>

          <DropdownMenu open={isDropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenu.Trigger asChild>
              <Button
                size="sm"
                intent="secondary"
                className="w-full justify-between"
                aria-expanded={isDropdownOpen}
                data-testid="select-sheet-button"
              >
                <span>{getSheetNames()[getSelectedSheetIndex()]}</span>
                <ChevronDownIcon size={20} />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="start" side="bottom" className="mt-2 w-48 p-2">
              {getSheetNames().map((sheetName, index) => (
                <DropdownMenu.Item
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onSelect={() => handleSheetSelection(index)}
                  data-testid="select-sheet-item"
                >
                  {sheetName}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu>
        </Card>
      )}

      <Card
        className={cn('overflow-auto sm:p-6', {
          'border border-destructive': columns.length === 0
        })}
        // 130 is the height of the table margins plus the height of the card below
        style={{
          height: showTopCard
            ? `calc(100vh - ${HEADER_HEIGHT * 4 + 100}px)`
            : `calc(100vh - ${HEADER_HEIGHT * 2 + 130}px)`
        }}
      >
        <div className="mb-5 flex items-center justify-between">
          <div>
            <Checkbox
              id="select-all-checkbox"
              checked={isAllFieldsSelected}
              data-testid="import-all-fields"
              onClick={onSelectUnSelectAllColumns}
            />
            <Label htmlFor="select-all-checkbox" className="ml-2">
              {t('components.add_table.all_fields')}
            </Label>
          </div>
          <div className="text-subtle" data-testid="total-visible-columns">
            {t('components.add_table.n_selected', {
              count: getTotalVisibleColumns()
            })}
          </div>
        </div>
        <Divider className="mb-2" />
        {existingTable && (
          <div className="mb-2 flex items-center justify-between text-xs">
            <div>{t('components.add_into_existing_table.column')}</div>
            <div>{t('components.add_into_existing_table.knack_field')}</div>
          </div>
        )}
        {columns.map((column, index) => {
          const isColumnVisible = getColumnVisibility(column);
          const { isThisColumnMapped, mappedColumnIndex, newFieldType, existingKnackField } =
            column.meta;
          const fieldTypesWithSubFields = ['name', 'address', 'link'];
          const availableFieldType = existingKnackField ? existingKnackField.type : newFieldType;
          // Show the dropdown only if this column is not mapped or if this column is mapped by itself
          const shouldShowFieldTypeDropdown =
            !isThisColumnMapped ||
            (isThisColumnMapped &&
              mappedColumnIndex !== undefined &&
              mappedColumnIndex === column.accessorKey);

          const shouldShowMappedToText =
            isThisColumnMapped &&
            mappedColumnIndex !== undefined &&
            mappedColumnIndex !== column.accessorKey;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${column}-${index}`}>
              <div className="mb-2 flex items-center justify-between">
                <div className="mr-6 flex items-center">
                  <Checkbox
                    id={`${column}-${index}-checkbox-item`}
                    checked={columnVisibility[index]}
                    disabled={isThisColumnMapped}
                    onClick={() => onColumnVisibilityChange(index)}
                  />
                  <Label
                    htmlFor={`${column}-${index}-checkbox-item`}
                    className={cn('ml-2 inline-block max-w-[150px] truncate', {
                      'text-subtle': !columnVisibility[index] || isThisColumnMapped
                    })}
                  >
                    {column.header}
                  </Label>
                </div>
                <div className="flex items-center">
                  {existingTable && <RightArrowIcon className="mr-4" />}
                  {shouldShowFieldTypeDropdown && <FieldTypeDropdown column={column} />}
                  {shouldShowMappedToText && (
                    <div className="w-48 text-subtle">
                      {t('components.add_table.mapped_to')}{' '}
                      {columns[mappedColumnIndex].meta.existingKnackField
                        ? t(
                            `attributes.field_types.${columns[mappedColumnIndex].meta.existingKnackField.type}`
                          )
                        : t(
                            `attributes.field_types.${columns[mappedColumnIndex].meta.newFieldType}`
                          )}
                    </div>
                  )}
                </div>
              </div>
              {newFieldType === 'date_time' && <DateTimeFormat column={column} />}

              {availableFieldType &&
                fieldTypesWithSubFields.includes(availableFieldType) &&
                !shouldShowMappedToText && (
                  <SubFields column={column} isColumnVisible={isColumnVisible} />
                )}
              {(newFieldType === 'connection' || existingKnackField?.type === 'connection') && (
                <ConnectionSubFields column={column} isColumnVisible={isColumnVisible} />
              )}
            </Fragment>
          );
        })}
      </Card>
      <Card className="flex items-center sm:p-6">
        <Switch
          className="mr-2"
          checked={hasHeaderRow}
          onCheckedChange={() => setHasHeaderRow(!hasHeaderRow)}
          id="hasHeaderRow"
          data-testid="use-first-row-as-header"
        />
        <label htmlFor="hasHeaderRow">{t('components.add_table.use_first_row')}</label>
      </Card>
    </div>
  );
}
