import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  EMAIL_RECIPIENT_MODE_OPTIONS,
  EMAIL_RECIPIENT_TYPE_OPTIONS,
  type RecordRule,
  type RecordRuleActionType,
  type RecordRuleEmailRecipient,
  type RecordRuleValue
} from '@/types/schema/rules/RecordRule';
import {
  useFieldHelpers,
  type ConnectionFieldWithRelationship
} from '@/hooks/helpers/useFieldHelpers';

export interface ConnectionFieldsWithRelationship {
  key: KnackObject['key'];
  connectedFieldKey: KnackFieldKey;
  connectedFieldName: string;
  sourceObjectName: string;
  fields: KnackField[];
}

export function useRecordRuleHelpers() {
  const [t] = useTranslation();
  const { getConnectionFieldsWithRelationship: getConnectedFieldsFromSourceObjects } =
    useFieldHelpers();

  const recordRuleSchema = z.object({
    key: z.string(),
    action: z.custom<RecordRuleActionType>(),
    criteria: z.custom<KnackCriteria[]>(),
    values: z.array(z.custom<RecordRuleValue>()),
    connection: z.custom<RecordRule['connection']>(),
    email: z
      .object({
        from_email: z.string().email(),
        from_name: z.string(),
        message: z.string(),
        subject: z.string().min(1, t('errors.value_required')),
        recipients: z
          .array(
            z.object({
              recipient_mode: z.enum(EMAIL_RECIPIENT_MODE_OPTIONS),
              recipient_type: z.enum(EMAIL_RECIPIENT_TYPE_OPTIONS),
              email: z.string().email(),
              field: z.custom<RecordRuleEmailRecipient['field']>()
            })
          )
          .optional()
      })
      .optional()
  });

  // Create a default value when changing the value type of the rule, clean the object when changing
  const createDefaultRecordRuleValue = (
    ruleValue: RecordRuleValue,
    ruleValueType: RecordRuleValue['type'],
    availableFields: KnackField[]
  ): RecordRuleValue => {
    const connectionFieldsWithRelationship: ConnectionFieldWithRelationship[] =
      getConnectedFieldsFromSourceObjects(availableFields);
    const { field } = ruleValue;
    const baseRuleValue = {
      field,
      value: ''
    };

    switch (ruleValueType) {
      case 'value':
        return {
          ...baseRuleValue,
          type: 'value'
        };

      case 'record':
        return {
          ...baseRuleValue,
          type: 'record',
          input: availableFields?.[0]?.key || ''
        };

      case 'connection':
        return {
          ...baseRuleValue,
          type: 'connection',
          connection_field: `${connectionFieldsWithRelationship[0]?.field.key || ''}-${connectionFieldsWithRelationship[0]?.connectionObject.fields[0]?.key || ''}`
        };

      default:
        return ruleValue;
    }
  };

  const getDefaultRecordValue = (defaultFieldKey: KnackFieldKey): RecordRuleValue => ({
    field: defaultFieldKey,
    type: 'value',
    value: ''
  });

  return {
    recordRuleSchema,
    createDefaultRecordRuleValue,
    getConnectedFieldsFromSourceObjects,
    getDefaultRecordValue
  };
}
