import { Label } from '@knack/asterisk-react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '@/utils/tailwind';

interface InlineRadioCardGroupProps {
  readonly options: readonly string[];
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
  itemClassName?: string;
}

export function InlineRadioCardGroup({
  options,
  value,
  onValueChange,
  className,
  itemClassName
}: InlineRadioCardGroupProps) {
  return (
    <RadioGroupPrimitive.Root
      className={cn('flex gap-2', className)}
      value={value}
      onValueChange={onValueChange}
    >
      {options.map((option) => (
        <RadioGroupPrimitive.Item
          key={option}
          value={option}
          className={cn(
            'flex items-center justify-center rounded-lg border border-default bg-default p-1 font-medium hover:border-emphasis hover:bg-subtle hover:text-emphasis data-[state=checked]:border-emphasis data-[state=checked]:bg-subtle data-[state=checked]:text-emphasis',
            itemClassName
          )}
        >
          <Label className="cursor-pointer">{option}</Label>
        </RadioGroupPrimitive.Item>
      ))}
    </RadioGroupPrimitive.Root>
  );
}
