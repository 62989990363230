import { useEffect, useMemo } from 'react';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { HiMiniTableCells as TableIcon } from 'react-icons/hi2';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@knack/asterisk-react';
import { t } from 'i18next';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { getValidTables } from '@/utils/tables';
import { DataTableStoreProvider } from '@/components/data-table/useDataTableStore';
import { EmptyState } from '@/components/EmptyState';
import { MainLayout } from '@/components/layout/MainLayout';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { FieldsStoreProvider } from '@/pages/tables/fields/useFieldsStore';
import { TablesTree } from '@/pages/tables/tables-tree/TablesTree';
import { TablesPageContextProvider } from '@/pages/tables/TablesPageContext';
import { useLastVisitedStore } from '@/pages/tables/useLastVisitedStore';
import { ROUTES } from '@/Router';
import { ToolkitSidebar } from './toolkit-sidebar/ToolkitSidebar';

function EmptyTablesState() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center p-[72px]" data-testid="tables-page-empty-state">
      <EmptyState>
        <EmptyState.Icon icon={<TableIcon />} />
        <EmptyState.Title>{t('components.empty_states.tables.title')}</EmptyState.Title>
        <EmptyState.Description>
          {t('components.empty_states.tables.description')}
        </EmptyState.Description>
        <EmptyState.Cta
          label={t('components.empty_states.tables.cta')}
          icon={<PlusIcon />}
          callback={() => navigate(`${ROUTES.TABLES_ADD}?origin=builder-next`)}
        />
        <EmptyState.Link
          link="https://learn.knack.com/article/ejdb2toq4i-import-records"
          linkText={t('components.empty_states.tables.link_text')}
          isExternalLink
        />
      </EmptyState>
    </div>
  );
}

export function TablesPage() {
  const { data: app, isLoading: isAppLoading } = useApplicationQuery();
  const tables = useMemo(() => getValidTables(app) || [], [app]);
  const urlParams = useParams<{ id: string }>();
  const { id: tableKey } = urlParams as { id: string };

  const { lastVisitedTableKey, setLastVisitedTableKey } = useLastVisitedStore((state) => ({
    lastVisitedTableKey: state.lastVisitedTableKey,
    setLastVisitedTableKey: state.setLastVisitedTableKey
  }));

  useEffect(() => {
    if (tableKey !== lastVisitedTableKey) {
      setLastVisitedTableKey(tableKey);
    }
  }, [tableKey, lastVisitedTableKey, setLastVisitedTableKey]);

  if (isAppLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <DataTableStoreProvider>
      <FieldsStoreProvider>
        <TablesPageContextProvider>
          <MainLayout rightSidebar={<ToolkitSidebar />} data-testid="main-layout">
            <ResizableCollapsiblePanel
              title={t('components.layout.left_sidebar.tables')}
              autoSaveId="tables-panel"
              panelContent={<TablesTree data-testid="tables-tree" />}
              mainContent={tables.length === 0 ? <EmptyTablesState /> : <Outlet />}
            />
          </MainLayout>
        </TablesPageContextProvider>
      </FieldsStoreProvider>
    </DataTableStoreProvider>
  );
}
