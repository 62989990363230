import { Trans, useTranslation } from 'react-i18next';

import { useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowConfirmContent() {
  const [t] = useTranslation();
  const { selectedViewSourcePath, selectedViewSource, selectedViewType } =
    useAddRecordViewFlowContext();

  if (!selectedViewSourcePath || !selectedViewSource || !selectedViewType) {
    return null;
  }

  let actionKey = 'displays';
  if (selectedViewType === 'form') {
    if (selectedViewSource?.recordDisplayQuantity === 'one') {
      actionKey = 'updates';
    } else {
      actionKey = 'inserts';
    }
  }

  // Lowercase the first letter
  const recordDisplayOption =
    selectedViewSourcePath.label.charAt(0).toLowerCase() + selectedViewSourcePath.label.slice(1);

  return (
    <div>
      <Trans
        i18nKey={`views.add_view_dialog.confirm_add_view.adding_element_that_${actionKey}`}
        values={{
          viewName: t(`views.view_names.${selectedViewType}`),
          recordDisplayOption
        }}
      >
        <span className="font-semibold">{t(`views.view_names.${selectedViewType}`)}</span>
        <span className="font-semibold">{recordDisplayOption}</span>
      </Trans>
    </div>
  );
}
