import { Trans, useTranslation } from 'react-i18next';
import { HiTableCells as TableIcon } from 'react-icons/hi2';
import { Button, Dialog } from '@knack/asterisk-react';

import { LearnMoreLink } from '@/components/LearnMoreLink';
import ManyToManyConnectionSvg from '@/assets/svg/connection-many-to-many.svg?react';
import OneToManyConnectionSvg from '@/assets/svg/connection-one-to-many.svg?react';

export type ConnectionType = 'inbound' | 'outbound';

export function RelationshipsInfoModal({
  setShouldRenderRelationshipsInfoModal,
  shouldRenderRelationshipsInfoModal
}: {
  setShouldRenderRelationshipsInfoModal: (value: boolean) => void;
  shouldRenderRelationshipsInfoModal: boolean;
}) {
  const [t] = useTranslation();

  const tableCard = (tableName: string) => (
    <div className="flex w-36 items-center gap-2 rounded-md border border-l-4 border-subtle border-l-brand p-2">
      <TableIcon size={16} className="shrink-0" />
      {tableName}
    </div>
  );

  const relationshipCard = (i18nPrefixKey: string, connectionImage: JSX.Element) => {
    const leftTableName = t(`${i18nPrefixKey}.left_table_name`);
    const rightTableName = t(`${i18nPrefixKey}.right_table_name`);

    return (
      <div className="flex flex-col gap-4 rounded-lg border border-default p-2">
        <p className="font-semibold">{t(`${i18nPrefixKey}.title`)}</p>
        <div className="flex gap-2">
          {tableCard(leftTableName)}
          {connectionImage}
          {tableCard(rightTableName)}
        </div>
        <div>
          <Trans
            i18nKey={`${i18nPrefixKey}.description`}
            components={[
              <span key="leftTableName" className="font-semibold">
                {leftTableName}
              </span>,
              <span key="rightTableName" className="font-semibold">
                {rightTableName}
              </span>,
              <>
                <br />
                <br />
              </>,
              <br key="break-line" />
            ]}
            values={{
              leftTableName,
              rightTableName
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={shouldRenderRelationshipsInfoModal}
      onOpenChange={setShouldRenderRelationshipsInfoModal}
    >
      <Dialog.Content data-testid="relationships-info-modal">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t('components.data_table.right_sidebar.connections.relationships_info_dialog.title')}
            </Dialog.Title>
          </Dialog.Header>
          <p className="mt-6">
            {t(
              'components.data_table.right_sidebar.connections.relationships_info_dialog.description'
            )}
          </p>
          <div className="mt-6 flex flex-col gap-3">
            <p className="font-medium">
              {t(
                'components.data_table.right_sidebar.connections.relationships_info_dialog.subtitle'
              )}
            </p>
            {relationshipCard(
              'components.data_table.right_sidebar.connections.relationships_info_dialog.one_to_many_card',
              <OneToManyConnectionSvg />
            )}
            {relationshipCard(
              'components.data_table.right_sidebar.connections.relationships_info_dialog.many_to_many_card',
              <ManyToManyConnectionSvg />
            )}
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="justify-between">
          <LearnMoreLink href="https://learn.knack.com/article/do520qb4jz-about-connections#managing_connections" />
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.close')}</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
