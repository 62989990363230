import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Select } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type RecordRule, type RecordRuleValue } from '@/types/schema/rules/RecordRule';
import { useRecordRuleHelpers } from '@/hooks/useRecordRuleHelpers';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { ViewRecordRuleValueInput } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleValueInput';

interface ViewRecordRuleDialogGroupItemProps {
  availableFields: KnackField[];
}

export function ViewRecordRuleValueGroup({ availableFields }: ViewRecordRuleDialogGroupItemProps) {
  const [t] = useTranslation();
  const { createDefaultRecordRuleValue, getDefaultRecordValue } = useRecordRuleHelpers();

  const {
    control,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext<RecordRule>();

  const {
    append,
    update,
    remove,
    fields: recordRuleValues
  } = useFieldArray({
    control,
    name: 'values'
  });

  const updateRecordTypes: RecordRuleValue['type'][] = ['value', 'record', 'connection'];

  const defaultRecordRuleValue = getDefaultRecordValue(availableFields[0].key);

  return recordRuleValues.map((ruleField, ruleFieldIndex) => (
    <div key={ruleField.id} className="flex gap-2">
      <div className="w-full">
        <div className="flex">
          <div className="-m-1 flex-1 space-y-2 p-1">
            <div className="flex gap-2">
              <div className="flex-1">
                <Controller
                  name={`values.${ruleFieldIndex}.field`}
                  render={({ field: { value: fieldKey } }) => {
                    const isValidFieldKey = availableFields.some((f) => f.key === fieldKey);

                    return (
                      <Select
                        value={isValidFieldKey ? fieldKey : undefined}
                        onValueChange={(newFieldKey: KnackFieldKey) => {
                          update(ruleFieldIndex, {
                            ...ruleField,
                            field: newFieldKey,
                            type: 'value',
                            value: ''
                          });
                          clearErrors();
                        }}
                        disabled={availableFields.length === 0}
                      >
                        <Select.Trigger
                          placeholder={t('actions.select')}
                          className={cn('w-full', {
                            'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                              errors.values?.[ruleFieldIndex]?.field
                          })}
                        />
                        <Select.Content>
                          {availableFields.map((field) => (
                            <Select.Item key={field.key} value={field.key}>
                              <span className="flex items-center">
                                <FieldIcon
                                  className="mr-2 shrink-0 text-subtle"
                                  size={16}
                                  name={field.type}
                                />
                                {field.name}
                              </span>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    );
                  }}
                />
                <FormErrorMessage
                  errors={errors}
                  name={`values.${ruleFieldIndex}.field`}
                  className="mt-1"
                />
              </div>
              <div className="flex-1">
                <Controller
                  name={`values.${ruleFieldIndex}.type`}
                  render={({ field: { value: valueType, onChange } }) => (
                    <Select
                      value={valueType}
                      onValueChange={(newValueType: RecordRuleValue['type']) => {
                        update(
                          ruleFieldIndex,
                          createDefaultRecordRuleValue(
                            getValues(`values.${ruleFieldIndex}`),
                            newValueType,
                            availableFields
                          )
                        );
                        onChange(newValueType);
                        clearErrors();
                      }}
                    >
                      <Select.Trigger
                        placeholder={`${t('actions.select')}...`}
                        className="w-full"
                      />
                      <Select.Content>
                        {updateRecordTypes.map((updateRecordType: string) => (
                          <Select.Item key={updateRecordType} value={updateRecordType}>
                            {t(`components.rules.record_rules.types.${updateRecordType}`)}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select>
                  )}
                />
              </div>
            </div>
            <ViewRecordRuleValueInput
              recordRuleActionType={ruleField.type}
              availableFields={availableFields}
              formFieldName={`values.${ruleFieldIndex}`}
            />
          </div>
          <div>
            <Button
              intent="minimal"
              aria-label={t('components.rules.delete_condition')}
              size="xs"
              className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
              onClick={() => remove(ruleFieldIndex)}
              disabled={recordRuleValues.length < 2}
            >
              <RemoveIcon size={16} />
            </Button>
            <Button
              intent="minimal"
              aria-label={t('components.rules.delete_condition')}
              size="xs"
              className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
              onClick={() => {
                append(defaultRecordRuleValue);
              }}
            >
              <AddIcon size={16} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  ));
}
