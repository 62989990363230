import { useTranslation } from 'react-i18next';

import { type BuilderViewSourceSchemaCriteria } from '@/types/schema/BuilderView';
import { type ReportView } from '@/types/schema/views/ReportView';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SourceFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersDialog';
import { SourceFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersGroupCard';
import { ReportDataDisplayCalculation } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplayCalculation';
import { ReportDataDisplayCategory } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplayCategory';
import { ReportTableInfo } from '@/pages/pages/settings-panel/view-settings/report/ReportTableInfo';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ReportDataDisplay() {
  const [t] = useTranslation();
  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));

  const { view, sourceObject } = useActiveViewContext<ReportView>();

  const updateViewSchema = useUpdateView<ReportView>();

  const handleSourceFiltersSubmit = (data: BuilderViewSourceSchemaCriteria) => {
    const newSchema = getBaseReportViewObject(view);
    newSchema.rows[0].reports[0].source.criteria = {
      match: 'any',
      rules: data.rules,
      groups: data.groups
    };
    updateViewSchema(newSchema);
  };

  return hasAccessToNonPublicFeatures ? (
    <>
      <ReportTableInfo />
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={`${t('pages.element_settings.report.categories.data_display.source_filters')}`}
        >
          <div className="space-y-4">
            <SourceFiltersGroupsCard
              sourceObject={sourceObject}
              sourceFiltersCriteria={view.rows[0].reports[0].source.criteria}
            />
            <SourceFiltersDialog
              sourceObject={sourceObject}
              sourceFiltersCriteria={view.rows[0].reports[0].source.criteria}
              onFormSubmit={handleSourceFiltersSubmit}
            />
          </div>
        </BuilderAccordion.Item>
        <ReportDataDisplayCategory />
        <ReportDataDisplayCalculation />
      </BuilderAccordion>
    </>
  ) : (
    <div className="text-subtle">{t('keywords.coming_soon')}</div>
  );
}
