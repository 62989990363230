import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type DateTimeRangeType } from '@/types/schema/fields/DateTimeField';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import {
  hideValueOperators,
  type HideValueOperator,
  type KnackOperator
} from '@/types/schema/KnackOperator';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { type ViewsWithFiltering } from '@/pages/pages/settings-panel/view-settings/common/filtering/types';
import { ViewSettingsDefaultFiltersDialogItem } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsDefaultFiltersDialogItem';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export type DefaultFiltersFormData = {
  preset_filters: KnackFilter[];
};

function ViewSettingsDefaultFiltersDialogContent({
  onFormSubmit
}: {
  onFormSubmit: (data: DefaultFiltersFormData) => void;
}) {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ViewsWithFiltering>();
  const { getOperatorsForField } = useFieldHelpers();

  const defaultFiltersRulesSchema = z
    .object({
      field: z.custom<KnackFieldKey>((val) => val.length, t('errors.value_required')),
      operator: z.custom<KnackOperator>((val) => val.length, t('errors.value_required')),
      value: z.union([z.string(), z.boolean(), z.array(z.string()), z.record(z.any())]),
      range: z.string().or(z.number()).optional(),
      type: z.custom<DateTimeRangeType>().optional()
    })
    .refine(
      (data) =>
        // if the operator is in the hideValueOperators array, the value is not required, otherwise it is
        hideValueOperators.includes(data.operator as HideValueOperator) || data.value,
      {
        message: t('errors.value_required'),
        path: ['value']
      }
    );

  const defaultFiltersFormSchema = z.object({
    preset_filters: z.array(defaultFiltersRulesSchema)
  });

  const form = useForm<DefaultFiltersFormData>({
    resolver: zodResolver(defaultFiltersFormSchema),
    defaultValues: {
      preset_filters: view.preset_filters
    }
  });

  const {
    fields: filterRules,
    append: addFilterRule,
    remove: removeFilterRule,
    update: updateFilterRule
  } = useFieldArray({
    control: form.control,
    name: 'preset_filters'
  });

  const hasDefaultFilters = filterRules.length > 0;

  const getDefaultCriteriaRule = (fieldKey: KnackFieldKey) => ({
    field: fieldKey,
    operator: getOperatorsForField(fieldKey, sourceObject.fields)[0],
    value: ''
  });

  return (
    <FormProvider {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.default_filters'
              )}
            </Dialog.Title>
            <Dialog.Description className="text-xs text-subtle">
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.default_filters_description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          {hasDefaultFilters && (
            <div className="mt-6">
              <p className="mb-4">
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
                )}
              </p>
              {filterRules.map((ruleField, ruleFieldIndex) => (
                <ViewSettingsDefaultFiltersDialogItem
                  sourceObject={sourceObject}
                  key={ruleField.id}
                  availableFields={sourceObject.fields}
                  formFieldName={`preset_filters.${ruleFieldIndex}`}
                  ruleField={ruleField}
                  ruleFieldIndex={ruleFieldIndex}
                  update={updateFilterRule}
                  remove={removeFilterRule}
                />
              ))}
            </div>
          )}
          <Button
            intent="secondary"
            className="mt-4 gap-1"
            onClick={() => {
              addFilterRule(getDefaultCriteriaRule(sourceObject.fields[0].key));
            }}
          >
            <Button.Icon icon={PlusIcon} />
            {t('keywords.filter')}
          </Button>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function ViewSettingsDefaultFiltersDialog() {
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSourceFiltersSubmit = (data: DefaultFiltersFormData) => {
    updateViewSchema({
      allow_preset_filters: true,
      preset_filters: data.preset_filters
    });

    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button intent="secondary">
          <PlusIcon size={16} className="mr-1" />
          {t('pages.element_settings.report.categories.filtering.default_filters')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <ViewSettingsDefaultFiltersDialogContent onFormSubmit={handleSourceFiltersSubmit} />
      </Dialog.Content>
    </Dialog>
  );
}
