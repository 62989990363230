import { useTranslation } from 'react-i18next';
import { Badge, Divider, Input, Label, Select, Switch, Tabs } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { TableDisplayRules } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/TableDisplayRules';
import { TableColumnSettingsActionButtonSection } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/TableColumnSettingsActionButtonSection';
import { TableRecordRules } from '@/pages/pages/settings-panel/view-settings/table/data-display/record-rules/TableRecordRules';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

// TODO: Finish this component when receive an update from Design and Product team
// https://knackhq.slack.com/archives/C049HLP66RK/p1728292997229349?thread_ts=1728049313.128179&cid=C049HLP66RK
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ColumnSettingsOpenLinkTabs({ defaultValue, onChange }) {
  const [t] = useTranslation();

  return (
    <div className="mt-4 space-y-2">
      <Label>
        {t(
          'pages.element_settings.common.categories.data_display.field_management.open_link_new_tab'
        )}
      </Label>
      <Tabs defaultValue={defaultValue} className="w-fit" onValueChange={onChange}>
        <Tabs.List
          items={[
            { value: 'modal', children: t('keywords.modal') },
            { value: 'newPage', children: t('keywords.new_page') }
          ]}
        />
      </Tabs>
      <div className="mt-4">
        <p>This will open a new</p>
      </div>
    </div>
  );
}

const fieldsTypesWithoutInLineEditing: KnackFieldType[] = [
  'auto_increment',
  'count',
  'sum',
  'max',
  'min',
  'average',
  'concatenation'
];

const fieldTypesAllowedForSummary: KnackFieldType[] = [
  'number',
  'currency',
  'min',
  'max',
  'sum',
  'average',
  'count',
  'rating'
];

export function TableViewColumnSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<TableView>();
  const { sourceObject } = useActiveViewContext<TableView>();
  const updateViewSchema = useUpdateView<TableView>();
  const { viewInputId } = useViewInputEditModeContext();

  const columnItem = view.columns.find((col) => col.id === viewInputId);

  if (!columnItem) {
    return null;
  }

  const isActionColumn = columnItem.type !== 'field';

  const columnItemInSettingsField = sourceObject.fields.find(
    (field) => field.key === columnItem?.field?.key
  );

  const shouldRenderInlineEditingOption =
    columnItemInSettingsField &&
    !fieldsTypesWithoutInLineEditing.includes(columnItemInSettingsField?.type) &&
    view.options?.cell_editor;
  const shouldRenderTruncateTextOption = columnItemInSettingsField?.type === 'paragraph_text';
  const shouldRenderAllowColumnSummariesOption =
    columnItemInSettingsField &&
    fieldTypesAllowedForSummary.includes(columnItemInSettingsField?.type) &&
    view.totals.length > 0;

  const updateViewColumn = (updatedData: Partial<TableViewColumn>) => {
    const newColumns = view.columns.map((col) => {
      if (col.id === updatedData.id) {
        return {
          ...col,
          ...updatedData
        };
      }

      return col;
    });

    updateViewSchema({
      columns: newColumns
    });
  };

  return (
    <div>
      <Divider className="mb-4" />
      <BuilderAccordion>
        <BuilderAccordion.Item
          label={t(
            'pages.element_settings.common.categories.data_display.field_management.column_label'
          )}
          isDefaultOpen
        >
          <Input
            value={columnItem.header}
            onChange={(e) => updateViewColumn({ id: columnItem.id, header: e.target.value })}
          />
        </BuilderAccordion.Item>
        {shouldRenderTruncateTextOption && (
          <>
            <Label className="flex items-center gap-2">
              <Switch
                checked={columnItem.truncate}
                onCheckedChange={(val) => updateViewColumn({ id: columnItem.id, truncate: val })}
              />
              {t(
                'pages.element_settings.table.categories.data_display.field_management.truncate_option'
              )}
            </Label>
            {columnItem.truncate && (
              <div className="ml-10 mt-4">
                <Label>
                  {t(
                    'pages.element_settings.table.categories.data_display.field_management.character_limit'
                  )}
                  <Input
                    className="max-w-20"
                    value={columnItem.character_limit}
                    onChange={(e) =>
                      updateViewColumn({
                        id: columnItem.id,
                        character_limit: Number(e.target.value)
                      })
                    }
                  />
                </Label>
              </div>
            )}
          </>
        )}
        {shouldRenderAllowColumnSummariesOption && (
          <Label className="flex items-center gap-2">
            <Switch
              checked={!columnItem.ignore_summary}
              onCheckedChange={(val) =>
                updateViewColumn({ id: columnItem.id, ignore_summary: !val })
              }
            />
            {t(
              'pages.element_settings.table.categories.data_display.field_management.allow_column_summaries'
            )}
          </Label>
        )}
        {shouldRenderInlineEditingOption && (
          <>
            <BuilderAccordion.Item
              label={t(
                'pages.element_settings.table.categories.data_display.field_management.inline_editing_option'
              )}
              isDefaultOpen
            >
              <Label className="flex items-center gap-2">
                <Switch
                  checked={!columnItem.ignore_edit}
                  onCheckedChange={(val) =>
                    updateViewColumn({ id: columnItem.id, ignore_edit: !val })
                  }
                />
                {t(
                  'pages.element_settings.table.categories.data_display.field_management.allow_inline_editing'
                )}
              </Label>
            </BuilderAccordion.Item>
            {!columnItem.ignore_edit && (
              <>
                <Divider className="my-4" />
                <BuilderAccordion.Item
                  label={t('components.rules.record_rules.title')}
                  isDefaultOpen
                >
                  {import.meta.env.MODE !== 'production' ? (
                    <TableRecordRules
                      column={columnItem}
                      updateViewColumn={updateViewColumn}
                      sourceObject={sourceObject}
                    />
                  ) : (
                    <Badge>{t('keywords.coming_soon')}</Badge>
                  )}
                </BuilderAccordion.Item>
              </>
            )}
          </>
        )}
        {!isActionColumn && (
          <BuilderAccordion.Item
            label={t(
              'pages.element_settings.common.categories.data_display.field_management.grouping'
            )}
            isDefaultOpen
            className="mt-4"
          >
            <div className="flex items-center gap-2">
              <Label className="flex items-center gap-1 text-subtle">
                <Switch disabled />
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.group_field_value'
                )}
              </Label>
              <Badge className="shrink-0 text-xs">{t('keywords.coming_soon')}</Badge>
            </div>
          </BuilderAccordion.Item>
        )}
        {isActionColumn && (
          <TableColumnSettingsActionButtonSection
            columnItem={columnItem}
            updateViewColumn={updateViewColumn}
          />
        )}
        <BuilderAccordion.Item
          label={t(
            'pages.element_settings.common.categories.data_display.field_management.display_rules'
          )}
          isDefaultOpen
        >
          <TableDisplayRules column={columnItem} updateViewColumn={updateViewColumn} />
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          label={t(
            'pages.element_settings.common.categories.data_display.field_management.field_formatting'
          )}
          isDefaultOpen
        >
          <Label className="font-medium">{t('keywords.width')}</Label>
          <Tabs
            defaultValue={columnItem.width.type}
            className="mb-4 mt-2 w-40"
            onValueChange={(val) =>
              updateViewColumn({
                id: columnItem.id,
                width: { ...columnItem.width, type: val as TableViewColumn['width']['type'] }
              })
            }
          >
            <Tabs.List
              items={[
                { value: 'default', children: t('keywords.default') },
                { value: 'custom', children: t('keywords.custom') }
              ]}
            />
            <Tabs.Content value="custom" className="p-0 text-default">
              <div className="flex w-full items-center gap-2">
                <Input
                  className="w-1/2"
                  value={columnItem.width.amount}
                  onChange={(e) =>
                    updateViewColumn({
                      id: columnItem.id,
                      width: { ...columnItem.width, amount: e.target.value }
                    })
                  }
                />

                <Select
                  value={columnItem.width.units}
                  onValueChange={(val: TableViewColumn['width']['units']) =>
                    updateViewColumn({
                      id: columnItem.id,
                      width: { ...columnItem.width, units: val }
                    })
                  }
                >
                  <Select.Trigger className="w-1/2" />
                  <Select.Content className="min-w-[100px]">
                    <Select.Item value="px">px</Select.Item>
                    <Select.Item value="pt">%</Select.Item>
                  </Select.Content>
                </Select>
              </div>
            </Tabs.Content>
          </Tabs>
          <Label className="font-medium">
            {t('pages.element_settings.common.categories.data_display.field_management.alignment')}
          </Label>
          <Tabs
            defaultValue={columnItem.align}
            className="mt-2 w-[200px]"
            onValueChange={(val) =>
              updateViewColumn({ id: columnItem.id, align: val as TableViewColumn['align'] })
            }
          >
            <Tabs.List
              items={[
                { value: 'left', children: t('keywords.left') },
                { value: 'center', children: t('keywords.center') },
                { value: 'right', children: t('keywords.right') }
              ]}
            />
          </Tabs>
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </div>
  );
}
