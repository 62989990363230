import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  HiMiniCommandLine as ApiIcon,
  HiLockClosed as LockIcon,
  HiCreditCard as PaymentsIcon,
  HiUser as UserIcon
} from 'react-icons/hi2';
import { Link, useLocation } from 'react-router-dom';

import { cn } from '@/utils/tailwind';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ROUTES } from '@/Router';

interface SettingsSectionTreeItemProps {
  name: string;
  to: string;
  icon: IconType;
  isActive: boolean;
}

function SettingsSectionTreeItem({ name, to, icon: Icon, isActive }: SettingsSectionTreeItemProps) {
  return (
    <div className="group relative">
      <Link
        to={to}
        className={cn([
          'flex h-9 w-full items-center justify-between gap-2 rounded-lg p-2',
          {
            'bg-brand-100 text-emphasis': isActive,
            'group-hover:bg-subtle': !isActive
          }
        ])}
      >
        <div
          className={cn('flex max-w-[calc(100%-30px)] items-center gap-2', {
            'max-w-[calc(100%-30px)]': isActive
          })}
          data-testid={`settings-section-tree-item-${name}`}
        >
          <Icon
            size={20}
            className={cn('mr-2 shrink-0 text-subtle', {
              'fill-[url(#svg-gradient-1)]': isActive
            })}
          />
          <span className="flex-1 truncate">{name}</span>
        </div>
      </Link>
    </div>
  );
}

export function SettingsSectionTree() {
  const [t] = useTranslation();
  const { pathname } = useLocation();

  const settingsSections = [
    {
      name: t('app_settings.sections.general'),
      to: ROUTES.SETTINGS_GENERAL_CORE,
      icon: LockIcon,
      isActive: pathname.includes(ROUTES.SETTINGS_GENERAL)
    },
    {
      name: t('app_settings.sections.user_logins'),
      to: ROUTES.SETTINGS_USERS,
      icon: UserIcon,
      isActive: pathname.includes(ROUTES.SETTINGS_USERS)
    },
    {
      name: t('app_settings.sections.payments'),
      to: ROUTES.SETTINGS_PAYMENTS,
      icon: PaymentsIcon,
      isActive: pathname.includes(ROUTES.SETTINGS_PAYMENTS)
    },
    {
      name: t('app_settings.sections.api'),
      to: ROUTES.SETTINGS_API,
      icon: ApiIcon,
      isActive: pathname.includes(ROUTES.SETTINGS_API)
    }
  ];

  return (
    <CollapsiblePanel.Content title={t('app_settings.heading')}>
      <div className="space-y-1">
        {settingsSections.map((section) => (
          <SettingsSectionTreeItem
            key={section.to}
            name={section.name}
            to={section.to}
            icon={section.icon}
            isActive={section.isActive}
          />
        ))}
      </div>
    </CollapsiblePanel.Content>
  );
}
